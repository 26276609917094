import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../utils/yupGlobal";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Login = () => {
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		document.title = "Đăng nhập";

		if (localStorage.getItem("access_token")) {
			navigate("/admin");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const login = (data) => {
		axios
			.post(`/login`, {
				username: data.username,
				password: data.password,
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					localStorage.setItem(
						"access_token",
						res.data.data.acess_token,
					);

					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					window.location.href = "/admin";
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			});
	};

	return (
		<Container>
			<div className="title text-center my-3">Đăng nhập</div>

			<Row className="justify-content-center">
				<Col xs="12" sm="8" md="6">
					<Card className="p-0 p-xl-5 mb-5 border-0">
						<Form
							onSubmit={handleSubmit((data) => {
								login(data);
							})}
						>
							<Input
								placeholder={"Tên đăng nhập"}
								name="username"
								register={register("username")}
								errors={errors.username}
								className="mb-3"
							/>
							<Input
								placeholder={"Mật khẩu"}
								type="password"
								name="password"
								register={register("password")}
								errors={errors.password}
								className="mb-3"
							/>

							<Button
								type="submit"
								size="lg"
								className="primary-button w-100"
							>
								Đăng nhập
							</Button>
						</Form>

						{/* <div className="mt-3 text-center">
							{language
								? "Chưa có tài khoản? "
								: "New customer? "}
							<Link
								style={{
									textDecoration: "none",
									color: "black",
								}}
								to={"/register"}
								className="d-inline fw-bold"
							>
								{language ? "Đăng ký ngay" : "Register now"}
							</Link>
						</div> */}
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

const Schema = yup.object().shape({
	password: yup.string().required("Không được bỏ trống"),
	username: yup.string().required("Không được bỏ trống"),
});

export default Login;
