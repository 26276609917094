import { Container } from "react-bootstrap";
import EnterpriseIntro from "../../../components/Enterprise/EnterpriseIntro";
import ListFeatures from "../../../components/Enterprise/ListFeatures";
import ListAdvantages from "../../../components/Enterprise/ListAdvantage";

const advantageItems = [
	{
		id: 1,
		icon: "https://vietteltelecom.vn/images/sme/v-check/feature_protect.png",
		title: "QR Code chống giả",
		content: "Cảnh báo vùng tem giả ngay lập tức",
	},
	{
		id: 2,
		icon: "https://vietteltelecom.vn/images/sme/v-check/feature_bag.png",
		title: "QR Code chống tràn hàng",
		content:
			"Dễ dàng quản lý phân phối theo vùng, chống tràn vùng lấn tuyến và chống bán phá giá.",
	},
	{
		id: 3,
		icon: "https://vietteltelecom.vn/images/sme/v-check/feature_setting.png",
		title: "QR Code bảo hành điện tử",
		content: "Số hóa toàn bộ quy trình bảo hành",
	},
	{
		id: 4,
		icon: "https://vietteltelecom.vn/images/sme/v-check/feature_search.png",
		title: "QR Code truy xuất nguồn gốc",
		content:
			"Theo dõi ghi chép nhật ký các hoạt động nội bộ, Chuỗi quy trình cung ứng, vận chuyển và bán sản phẩm ra thị trường.",
	},
];

const featureItems = [
	{
		id: 1,
		icon: "https://vietteltelecom.vn/images/sme/v-check/benefit_search.png",
		title: "QR CODE TRUY XUẤT NGUỒN GỐC",
		content:
			"Đảm bảo minh bạch thông tin sản phẩm, Truy xuất nguồn gốc theo tiêu chuẩn Quốc gia",
	},
	{
		id: 2,
		icon: "https://vietteltelecom.vn/images/sme/v-check/benefit_chat.png",
		title: "QR CODE BẢO HÀNH ĐIỆN TỬ",
		content: "Quản lý dễ dàng thông tin khách hàng",
	},
	{
		id: 3,
		icon: "https://vietteltelecom.vn/images/sme/v-check/benefit_fake.png",
		title: "QR CODE CẢNH BÁO CHỐNG GIẢ",
		content: "Chống hàng giả, tăng uy tín cho thương hiệu trên thị trường",
	},
	{
		id: 4,
		icon: "https://vietteltelecom.vn/images/sme/v-check/benefit_ship.png",
		title: "QR CODE CHỐNG TRÀN HÀNG",
		content: "Kiểm soát lượng hàng hóa phân phối",
	},
];

const VMark = () => {
	return (
		<>
			<div
				style={{
					aspectRatio: "1921/851",
					backgroundImage: `url(https://media.vietteltelecom.vn/upload/e1/c1/1e/ae2c31c2548f44a1e5d187bdfec37b854b2e884f.jpg)`,
				}}
				className="image-default mb-5"
			/>

			<Container className="container-wrapper">
				<EnterpriseIntro
					title="VMARK LÀ GÌ?"
					description={
						"vMark là giải pháp do Viettel cung cấp ứng dụng QR code vào việc truy xuất nguồn gốc sản phẩm, giúp theo dõi, nhận diện sản phẩm qua từng công đoạn: sản xuất, chế biến, phân phối..."
					}
					className={`text-center`}
					buttonClass={`justify-content-center`}
				/>
			</Container>

			{/* Advantage */}
			<ListFeatures
				title="LỢI ÍCH CỦA SẢN PHẨM ĐỐI VỚI DOANH NGHIỆP"
				items={featureItems}
				backgroundImage={`https://vietteltelecom.vn/images/sme/v-check/benefit.png`}
			/>

			<Container className="container-wrapper">
				{/* Feature */}
				<ListAdvantages
					title="TÍNH NĂNG SẢN PHẨM"
					items={advantageItems}
					col={4}
				/>
			</Container>
		</>
	);
};

export default VMark;
