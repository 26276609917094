import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Container } from "react-bootstrap";
import MyBreadcrumb from "../../../components/MyBreadcrumb";
import CarouselBanner from "../../../components/CarouselBanner";
import ListDataPackage from "../../../components/Mobile/DataPackage/ListDataPackage";

import { MOBILE } from "../../../routes/routerUrl";

import "./MobileStyle.css";

const Mobile = () => {
	const [dataPackages, setDataPackages] = useState([]);
	const [isLoadingData, setIsLoadingData] = useState(false);

	const [mainPackages, setMainPackages] = useState([]);
	const [isLoadingMain, setIsLoadingMain] = useState(false);

	useEffect(() => {
		document.title = "Dịch vụ di động của mạng viễn thông Viettel Telecom";

		getAllDataPackages();
		getAllMainPackages();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const getAllDataPackages = async () => {
		setIsLoadingData(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 1,
					keyword: "",
					limit: 20,
				},
			})
			.then((res) => {
				setIsLoadingData(false);
				if (res.data.errCode === 0) {
					setDataPackages(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllMainPackages = async () => {
		setIsLoadingMain(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 3,
					keyword: "",
					limit: 20,
				},
			})
			.then((res) => {
				setIsLoadingMain(false);
				if (res.data.errCode === 0) {
					setMainPackages(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			<CarouselBanner />

			<Container className="container-wrapper">
				<MyBreadcrumb list={["Dịch vụ di động"]} />

				<ListDataPackage
					title="GÓI DATA"
					allLink={`${MOBILE}/goi-data`}
					itemLink="goi-data"
					listItems={dataPackages}
					isLoading={isLoadingData}
				/>

				<div className="my-5" />

				<ListDataPackage
					title="GÓI CƯỚC CHÍNH"
					allLink={`${MOBILE}/goi-cuoc`}
					itemLink="goi-cuoc"
					listItems={mainPackages}
					isLoading={isLoadingMain}
				/>
			</Container>
		</>
	);
};

export default Mobile;
