const responsive4Items = {
	large: {
		breakpoint: { max: 3000, min: 1200 },
		items: 4,
		slidesToSlide: 1,
	},
	desktop: {
		breakpoint: { max: 1200, min: 768 },
		items: 3,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 768, min: 576 },
		items: 2,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 576, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const responsive3Items = {
	large: {
		breakpoint: { max: 3000, min: 1200 },
		items: 3,
		slidesToSlide: 1,
	},
	desktop: {
		breakpoint: { max: 1200, min: 768 },
		items: 3,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 768, min: 576 },
		items: 2,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 576, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const responsive2Items = {
	large: {
		breakpoint: { max: 3000, min: 1200 },
		items: 2,
		slidesToSlide: 1,
	},
	desktop: {
		breakpoint: { max: 1200, min: 768 },
		items: 2,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 768, min: 576 },
		items: 2,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: { max: 576, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

export { responsive4Items, responsive3Items, responsive2Items };
