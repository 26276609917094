import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Col, Container, Row } from "react-bootstrap";

import MyBreadcrumb from "../../../../components/MyBreadcrumb";
import DataPackageItem from "../../../../components/Mobile/DataPackage/DataPackageItem";
import ClientLoading from "../../../../components/ClientLoading";
import NoData from "../../../../components/NoData";

const DataPackage = () => {
	const [serviceTypes, setServiceTypes] = useState([]);
	const [dataPackages, setDataPackages] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Gói cước Data";
		getServiceTypeChilds();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getServiceTypeChilds = () => {
		axios
			.get(`/child/serviceType`, {
				params: {
					serviceTypeId: 1,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setServiceTypes(res.data.serviceTypes);
					getAllDataPackages(res.data.serviceTypes[0].slug);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllDataPackages = async (slug) => {
		setIsLoading(true);
		await axios
			.get(`/serviceType/service/${slug}`)
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setDataPackages(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<Container className="container-wrapper">
			<MyBreadcrumb list={["Dịch vụ di động", "Gói cước Data"]} />
			<Row className="mx-0">
				{isLoading ? <ClientLoading /> : ""}

				{serviceTypes?.length > 0 ? (
					<div className="display-flex-center mb-5">
						{serviceTypes?.map((tabItem) => {
							return (
								<div
									key={tabItem.id}
									style={{ fontSize: "1.2rem" }}
									className="section-tab"
									onClick={() => {
										getAllDataPackages(tabItem.slug);
									}}
								>
									{tabItem.name}
								</div>
							);
						})}
					</div>
				) : (
					""
				)}

				{dataPackages.length > 0 ? (
					dataPackages.map((item) => {
						return (
							<Col
								xs="6"
								sm="4"
								md="3"
								key={item.id}
								className="px-1 px-2 mb-3"
							>
								<DataPackageItem item={item} link="goi-data" />
							</Col>
						);
					})
				) : (
					<NoData />
				)}
			</Row>
		</Container>
	);
};

export default DataPackage;
