import { useEffect } from "react";
import { Container } from "react-bootstrap";
import MyBreadcrumb from "../../../components/MyBreadcrumb";
import CarouselBanner from "../../../components/CarouselBanner";
import ListEventNew from "../../../components/EventNew/ListEventNew";

const NewEvent = () => {
	useEffect(() => {
		document.title = "Tin tức sự kiện";
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<CarouselBanner />

			<Container className="container-wrapper">
				<MyBreadcrumb list={["Tin tức sự kiện"]} />

				<ListEventNew isPagiantion={true} />
			</Container>
		</>
	);
};

export default NewEvent;
