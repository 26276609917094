import {
	ENTERPRISE_SERVICE,
	INTERNET_TV,
	MOBILE,
	MY_SIGN,
	S_INVOICE,
	VIETTEL_CA,
	V_CONTRACT,
	V_MARK,
} from "../routes/routerUrl";

const navbarItems = [
	{
		id: 1,
		title: "Dịch vụ di động",
		link: MOBILE,
		subTitle: [
			{
				id: 1,
				title: "Gói cước data",
				link: `${MOBILE}/goi-data`,
			},
			// {
			// 	id: 2,
			// 	title: "Gói thoại/sms",
			// 	link: MOBILE,
			// },
			{
				id: 3,
				title: "Gói cước chính",
				link: `${MOBILE}/goi-cuoc`,
			},
		],
	},
	{
		id: 2,
		title: "Internet / Truyền Hình",
		link: INTERNET_TV,
		subTitle: [
			{
				id: 1,
				title: "Internet",
				link: `${INTERNET_TV}/internet`,
			},
			{
				id: 2,
				title: "Truyền hình",
				link: `${INTERNET_TV}/truyen-hinh`,
			},
			{
				id: 3,
				title: "Combo Internet / Truyền Hình",
				link: `${INTERNET_TV}/combo`,
			},
		],
	},
	{
		id: 3,
		title: "Dịch vụ doanh nghiệp",
		link: ENTERPRISE_SERVICE,
		subTitle: [
			{
				id: 1,
				title: "Chữ ký số - ViettelCA",
				link: VIETTEL_CA,
			},
			{
				id: 2,
				title: "Hóa đơn điện tử - SInvoice",
				link: S_INVOICE,
			},
			{
				id: 3,
				title: "Hợp đồng điện tử - vContract",
				link: V_CONTRACT,
			},
			{
				id: 4,
				title: "Truy xuất nguồn gốc - vMark",
				link: V_MARK,
			},
			{
				id: 5,
				title: "Chữ ký số từ xa - MySign",
				link: MY_SIGN,
			},
		],
	},
	// {
	// 	id: 4,
	// 	title: "Hỗ trợ khách hàng",
	// 	link: CUSTOMER_HELP,
	// 	subTitle: [
	// 	],
	// },
];

export default navbarItems;
