import { Col, Row } from "react-bootstrap";
import EnterpriseSection from "./EnterpriseSection";

const AdvantageItem = ({ item }) => {
	return (
		<div
			style={{
				backgroundColor: "#f2f2f2",
				fontSize: "1.2rem",
				padding: "1.5rem",
			}}
			className="text-center h-100"
		>
			<div className="d-flex justify-content-center mb-4">
				<div
					style={{
						height: "5rem",
						width: "5rem",
						backgroundImage: `url(${item.icon})`,
					}}
					className="image-default top-radius-8 text-uppercase"
				/>
			</div>
			<div className="fw-bold my-2 my-md-3">{item.title}</div>
			<div>{item.content}</div>
		</div>
	);
};

const ListAdvantages = ({ title, items, col }) => {
	if (col === 5) {
		return (
			<EnterpriseSection title={title}>
				<div className="d-flex flex-wrap justify-content-center h-100">
					{items.map((item) => {
						return (
							<div
								key={item.id}
								className="enterprise-advan-item text-center p-2"
							>
								<AdvantageItem item={item} />
							</div>
						);
					})}
				</div>
			</EnterpriseSection>
		);
	}

	return (
		<EnterpriseSection title={title}>
			<Row>
				{items.map((item) => {
					return (
						<Col key={item.id} xs="12" md="3" className="mb-3">
							<AdvantageItem item={item} />
						</Col>
					);
				})}
			</Row>
		</EnterpriseSection>
	);
};

export default ListAdvantages;
