import { Link } from "react-router-dom";

const Section = ({
	title,
	allLink,
	tabItems,
	tabOnClick,
	tabIsActive,
	children,
}) => {
	return (
		<div className="my-4">
			<div className="d-flex justify-content-between align-items-top align-items-xl-center mb-3">
				<div className="d-block d-xl-flex">
					<div className="section-title me-3 me-xl-5">{title}</div>

					{tabItems?.length > 0 ? (
						<div className="d-flex align-items-center mt-2 mb-3 my-xl-0">
							{tabItems?.map((tabItem) => {
								return (
									<div
										key={tabItem.id}
										className={
											tabIsActive === tabItem.id
												? "section-tab-active"
												: "section-tab"
										}
										onClick={() => tabOnClick(tabItem)}
									>
										{tabItem.name}
									</div>
								);
							})}
						</div>
					) : (
						""
					)}
				</div>

				{allLink ? (
					<Link to={allLink} className="underline-link mt-2 mt-0">
						Xem tất cả
					</Link>
				) : (
					""
				)}
			</div>

			{children}
		</div>
	);
};

export default Section;
