import { Col, Row } from "react-bootstrap";
import EnterpriseSection from "./EnterpriseSection";
import Icon from "../Icon";

const ListWhys2 = ({ title, items }) => {
	return (
		<div
			style={{
				height: "fit-content",
				backgroundImage: `url(https://vietteltelecom.vn/images/sme/smev3/banner-user-object.png)`,
				backgroundSize: "cover",
			}}
			className="image-default p-3 p-md-4"
		>
			<EnterpriseSection title={title}>
				<div className="d-flex justify-content-center">
					<div
						style={{
							height: "12rem",
							width: "18rem",
							backgroundImage: `url(https://vietteltelecom.vn/images/sme/smev3/Frame%209498.png)`,
						}}
						className="image-default my-3 my-md-5"
					/>
				</div>

				<Row className="mx-0">
					{items.map((item) => {
						return (
							<Col key={item.id} xs="6" className="mb-2 h-100">
								<Row>
									<Col
										xs="1"
										className="px-0 d-flex justify-content-end pt-1"
									>
										<div
											style={{
												borderRadius: "50%",
												backgroundColor:
													"rgba(238, 0, 51, 0.1)",
												width: "1rem",
												height: "1rem",
											}}
											className="display-flex-center"
										>
											<Icon
												icon="check"
												style={{
													color: "var(--primary-color)",
													fontSize: "0.5rem",
												}}
											/>
										</div>
									</Col>

									<Col xs="10" md="11">
										{item.content}
									</Col>
								</Row>
							</Col>
						);
					})}
				</Row>
			</EnterpriseSection>
		</div>
	);
};

export default ListWhys2;
