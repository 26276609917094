import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";

import { Container } from "react-bootstrap";
import CarouselBanner from "../../../components/CarouselBanner";
import EnterpriseItem from "../../../components/Enterprise/EnterpriseItem";
import EnterpriseSection from "../../../components/Enterprise/EnterpriseSection";
import ListEventNewBottom from "../../../components/EventNew/ListEventNewBottom";

import { packages } from "../../../InitialData";
import { responsive3Items } from "../../../utils/carouselResponsiveItem";

import "./EnterpriseStyle.css";

const enterpriseTabItems = [
	{
		id: 1,
		title: "ViettelCA",
		tabLink: "viettelCA",
	},
	{
		id: 2,
		title: "SInvoice",
		tabLink: "sInvoice",
	},
	{
		id: 3,
		title: "vContract",
		tabLink: "cContract",
	},
	{
		id: 4,
		title: "vMark",
		tabLink: "vMark",
	},
	{
		id: 5,
		title: "MYSIGN",
		tabLink: "mysign",
	},
];

const Enterprise = () => {
	const [selectedTab, setSelectedTab] = useState(1);

	useEffect(() => {
		document.title = "Các giải pháp số doanh nghiệp của Viettel Telecom";
	}, []);
	return (
		<>
			<CarouselBanner />
			<Container>
				<EnterpriseSection title="GIẢI PHÁP CNTT">
					<div className="d-flex justify-content-between mb-4">
						{enterpriseTabItems.map((item) => {
							return (
								<div
									key={item.id}
									style={{
										color: "var(--black)",
										borderBottom: "1px solid var(--black)",
										fontWeight: 600,
										cursor: "pointer",
									}}
									className={`${
										selectedTab === item.id
											? "section-tab-active"
											: "section-tab"
									} px-3 px-md-5 p-2`}
									onClick={() => setSelectedTab(item.id)}
								>
									{item.title}
								</div>
							);
						})}
					</div>

					<div
						style={{
							color: "white",
							backgroundImage: `url(https://media.vietteltelecom.vn/upload/20/a9/92/6b7ac19945b99e877e0247f5dda0ab210b608ac4.jpg)`,
						}}
						className="enterprise-bg-image image-default px-3 py-4 mb-3"
					>
						<div className="item-title">Chữ ký số - ViettelCA</div>
						<div className="my-1 my-md-3 tab-text-width text-overflow-4-line">
							Viettel CA - Chữ ký số Viettel (hay còn gọi là Chứng
							thư số Viettel) là dịch vụ giải pháp hiện đại an
							toàn và bảo mật, toàn bộ thông tin dữ liệu được xử
							lý mã hóa và đảm bảo tính đầy đủ của doanh nghiệp.
							Chữ ký số dùng để ký số hóa điện tử thay cho chữ ký
							tay thông thường trên các loại văn bản hoặc tài liệu
							số thông qua mạng Internet.
						</div>
						<Link
							style={{ color: "var(--white)" }}
							className="normal-link"
						>
							Xem chi tiết dịch vụ {">"}
						</Link>
					</div>

					<Carousel
						arrows={false}
						responsive={responsive3Items}
						autoPlay
						infinite={true}
					>
						{packages.map((item) => {
							return (
								<EnterpriseItem
									key={item.id}
									item={item}
									className="me-2"
								/>
							);
						})}
					</Carousel>

					<div className="section-title my-3">
						Danh sách combo liên quan
					</div>

					<Carousel
						arrows={false}
						responsive={responsive3Items}
						autoPlay
						infinite={true}
					>
						{packages.map((item) => {
							return (
								<EnterpriseItem
									key={item.id}
									item={item}
									className="me-2"
								/>
							);
						})}
					</Carousel>
				</EnterpriseSection>

				<EnterpriseSection title="TIN TỨC SỰ KIỆN">
					<ListEventNewBottom />
				</EnterpriseSection>
			</Container>
		</>
	);
};

export default Enterprise;
