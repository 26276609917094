import Carousel from "react-multi-carousel";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { INTERNET_TV } from "../../routes/routerUrl";

import InternetBG3 from "../../assets/banner/internet-bg-3.svg";
import IconHomeIT from "../../assets/icon/icon-it.svg";
import MoneyFormat from "../MoneyFormat";

import { responsive4Items } from "../../utils/carouselResponsiveItem";
import NoData from "../NoData";
import Loading from "../Loading";
import Section from "../Section";

const ListInternetTVDetail = ({
	title,
	allLink,
	className,
	listItems,
	isLoading,
}) => {
	return (
		<Section title={title} allLink={allLink}>
			{isLoading ? (
				<Loading />
			) : listItems?.length > 0 ? (
				<Carousel
					responsive={responsive4Items}
					autoPlay
					infinite={true}
				>
					{listItems?.map((item) => {
						return (
							<div
								key={item.id}
								style={{ backgroundColor: "var(--white)" }}
								className={`text-center radius-8 hover-border ${className}`}
							>
								<Link
									to={`${INTERNET_TV}/goi-cuoc/${item.slug}`}
									style={{ textDecoration: "none" }}
								>
									<div
										style={{
											aspectRatio: "263/85",
											backgroundImage: `url(${InternetBG3})`,
											color: "var(--white)",
											fontSize: "2rem",
										}}
										className="image-default top-radius-8 item-title text-uppercase display-flex-center"
									>
										{item.name}
									</div>
								</Link>

								<div
									style={{
										aspectRatio: "8/1",
										backgroundImage: `url(${IconHomeIT})`,
									}}
									className="image-default"
								/>

								<div>
									<div className="item-title text-overflow-1-line">
										{item.speed}
									</div>

									<div className="text-overflow-1-line">
										<MoneyFormat
											money={item.price}
											className="item-title "
										/>

										<div className="d-inline fw-bold">
											/{item.period}
										</div>
									</div>
								</div>

								<Button className="primary-button w-75 my-3">
									Xem chi tiết
								</Button>

								<Link
									to={`${INTERNET_TV}/goi-cuoc/${item.slug}`}
								>
									<Button className="mute-button w-75 mb-3">
										Xem chi tiết
									</Button>
								</Link>
							</div>
						);
					})}
				</Carousel>
			) : (
				<NoData />
			)}
		</Section>
	);
};

export default ListInternetTVDetail;
