import { Col, Row } from "react-bootstrap";
import EnterpriseSection from "./EnterpriseSection";

const ListWhys = ({ title, items }) => {
	return (
		<EnterpriseSection title={title}>
			<Row>
				{items.map((item) => {
					return (
						<Col key={item.id} xs="12" md="3" className="mb-3">
							<div
								style={{
									fontSize: "1.2rem",
									padding: "1.5rem",
								}}
								className="text-center h-100"
							>
								<div className="d-flex justify-content-center mb-4">
									<div
										style={{
											height: "5rem",
											width: "5rem",
											backgroundImage: `url(${item.icon})`,
										}}
										className="image-default top-radius-8 text-uppercase"
									/>
								</div>
								<div className="fw-bold my-2 my-md-5">
									{item.title}
								</div>
								<div>{item.content}</div>
							</div>
						</Col>
					);
				})}
			</Row>
		</EnterpriseSection>
	);
};

export default ListWhys;
