import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Col, Container, Row } from "react-bootstrap";
import MyBreadcrumb from "../../../components/MyBreadcrumb";
import ClientLoading from "../../../components/ClientLoading";
import ListCombo2 from "../../../components/InternetTV/Combo/ListCombo2";
import BottomBannerInternetTV from "../../../components/InternetTV/BottomBannerInternetTV";

import ComboPackBG from "../../../assets/banner/bg-combo-pack.png";

const Combo = () => {
	const [topCombos, setTopCombos] = useState([]);
	const [bottomCombos, setBottomCombos] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Combo";
		getAllCombos();
	}, []);

	// API
	const getAllCombos = async () => {
		setIsLoading(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 6,
					keyword: "",
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setTopCombos(
						res.data.services.filter(
							(service) => service.serviceTypeId === 16,
						),
					);
					setBottomCombos(
						res.data.services.filter(
							(service) => service.serviceTypeId === 17,
						),
					);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<>
			<Container className="container-wrapper">
				<MyBreadcrumb
					list={[
						"Internet/Truyền hình",
						"Combo Internet/Truyền hình",
					]}
				/>
			</Container>

			<div
				style={{ backgroundColor: "var(--white)" }}
				className="text-center fw-bold"
			>
				<div className="inter-title p-5">
					MỞ RA THẾ GIỚI BẤT TẬN VỚI COMBO INTERNET VÀ TRUYỀN HÌNH
				</div>

				<div className="item-text">
					Quý khách vui lòng chọn tỉnh thành phố để tham khảo gói cước
					phù hợp nhất
				</div>
			</div>

			<div
				style={{
					backgroundImage: `url(${ComboPackBG})`,
				}}
				className="combo-image-bg image-default mb-4"
			>
				<Container className="container-wrapper h-100">
					{isLoading ? <ClientLoading /> : ""}

					<Row className="align-items-center h-100">
						<Col xs="12" md="8">
							<div className="item-title mb-5">
								COMBO NETPLUS & TV360APP
							</div>

							<ListCombo2 listItems={topCombos} />
						</Col>

						<Col xs="12" md="4" />
					</Row>
				</Container>
			</div>

			<Container className="container-wrapper">
				<ListCombo2
					title={`COMBO SUPERNET & TV360APP`}
					listItems={bottomCombos}
					colShow={true}
				/>
			</Container>

			<BottomBannerInternetTV />
		</>
	);
};

export default Combo;
