import { Container } from "react-bootstrap";
import EnterpriseIntro2 from "../../../components/Enterprise/EnterpriseIntro2";
import ListAdvantages2 from "../../../components/Enterprise/ListAdvantages2";
import ListWhys2 from "../../../components/Enterprise/ListWhys2";
import ListSell2 from "../../../components/Enterprise/ListSell2";
import { packages } from "../../../InitialData";
import BottomBannerEnterprise from "../../../components/Enterprise/BottomBannerEnterprise";
import ListFeatures2 from "../../../components/Enterprise/ListFeatures2";

const advantageItems = [
	{
		id: 1,
		icon: "https://vietteltelecom.vn/images/sme/smev3/setting.png",
		content:
			"Đơn giản, dễ sử dụng, không yêu cầu cài đặt, thiết lập sever, chỉ cần sử dụng máy tính kết nối Internet, phù hợp với đối tượng HKD",
	},
	{
		id: 2,
		icon: "https://vietteltelecom.vn/images/sme/smev3/quality.png",
		content:
			"Liên kết các nghiệp vụ cốt lõi, đồng thời mở rộng tích hợp với các dịch vụ trong hệ sinh thái các sản phẩm số của Viettel như: Chữ ký số (CA), Hóa đơn điện tử.",
	},
	{
		id: 3,
		icon: "https://vietteltelecom.vn/images/sme/smev3/control.png",
		content:
			"Đáp ứng các nghiệp vụ về kế toán theo Thông tư 88/2021/TT-BTC và các quy định khác của Bộ Tài Chính.",
	},
	{
		id: 4,
		icon: "https://vietteltelecom.vn/images/sme/smev3/support.png",
		content:
			"Tiết kiệm chi phí ngay cả khi mua đơn lẻ PMKT (vESS) hoặc Combo (PMKT+CA+HDDT).",
	},
];

const featureItems = [
	{
		id: 1,
		content: "Công nghệ duy nhất có tính pháp lý, được pháp luật công nhận",
	},
	{
		id: 2,
		content: "Đạt tiêu chuẩn quốc tế về bảo mật",
	},
	{
		id: 3,
		content: "Hỗ trợ nhiều giao dịch điện tử",
	},
	{
		id: 4,
		content: "Giao dịch điện tử được đảm bảo an toàn tuyệt đối",
	},
];

const whyItems = [
	{
		id: 1,
		content: "Nhà phân phối bán lẻ, shop online: quản lý tương tác đa kênh",
	},
	{
		id: 2,
		content:
			"Du lịch, lữ hành, khách hàng, nhà hàngm: tư vấn, đặt chỗ, khảo sát chất lượng dịch vụ",
	},
	{
		id: 3,
		content:
			"Tài chính, bảo hiểm, bất động sản: phục vụ tư vấn bán hàng, giải đáp/ hỗ trợ khách hàng, nhắc lịch/cảnh báo khách hàng tự động",
	},
	{
		id: 4,
		content:
			"Giao thông vận tải (nhà xe, hãng taxi, dịch vụ logistics): giải đáp/hỗ trợ khách hàng",
	},
	{
		id: 5,
		content:
			"Chăm sóc sức khỏe và sắc đẹp (bệnh viện, phòng khám, spa): đặt lịch khám, hướng dẫn quy trình khám bệnh, khảo sát chất lượng dịch vụ",
	},
	{
		id: 6,
		content:
			"Tư vấn và giáo dục: đặt lịch tư vấn, giải đáp/hỗ trợ học viên, phụ huynh, khách hàng",
	},
	{
		id: 7,
		content: "Doanh nghiệp cần điều hành quản lý chuỗi hệ thống chi nhánh",
	},
];

const MySign = () => {
	return (
		<>
			<Container className="container-wrapper">
				<EnterpriseIntro2
					title={`DỊCH VỤ MY SIGN LÀ GÌ`}
					description={`MySign là một dạng chữ ký điện tử với các thông tin được mã hóa bằng khóa riêng của người gửi, được đính kèm theo văn bản nhằm đảm bảo cho người nhận định danh và xác định đúng nguồn gốc, tính toàn vẹn của dữ liệu nhận được.`}
					image={`https://viettel.vn/images/sme/smev3/img-mysign.png`}
					className={`text-center`}
				/>
			</Container>

			<ListAdvantages2
				title={`LỢI ÍCH CỦA SẢN PHẨM`}
				items={advantageItems}
				col={4}
			/>

			<Container className="container-wrapper">
				{/* Feature */}
				<ListFeatures2
					title={`TÍNH NĂNG SẢN PHẨM`}
					items={featureItems}
				/>
			</Container>

			<ListWhys2
				title={`ĐỐI TƯỢNG NÀO NÊN SỬ DỤNG GIẢI PHÁP`}
				items={whyItems}
			/>

			<Container className="container-wrapper">
				{/* Sell */}
				<ListSell2
					title={`THÔNG TIN CÁC GÓI CƯỚC`}
					items={packages}
					type={2}
				/>

				<BottomBannerEnterprise
					title={`HỆ THỐNG QUẢN TRỊ PHẦN MỀM DỄ DÀNG`}
				/>
			</Container>
		</>
	);
};

export default MySign;
