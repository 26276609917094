import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";

import Section from "../../../components/Section";
import ComboItem from "../../../components/InternetTV/Combo/ComboItem";
import BottomBannerInternetTV from "../../../components/InternetTV/BottomBannerInternetTV";
import MyBreadcrumb from "../../../components/MyBreadcrumb";

import TVBg from "../../../assets/banner/bg-tv.png";

import { responsive3Items } from "../../../utils/carouselResponsiveItem";
import ListTV from "../../../components/InternetTV/TV/ListTV";

const TV = () => {
	const [tvs, setTVs] = useState([]);
	const [combos, setCombos] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Truyền hình";
		getAllTVs();
		getAllCombos();
	}, []);

	// API
	const getAllTVs = async () => {
		setIsLoading(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 5,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setTVs(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllCombos = async () => {
		setIsLoading(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 6,
					keyword: "",
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setCombos(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<div style={{ backgroundColor: "var(--black)", color: "var(--white)" }}>
			<div
				style={{
					height: "20rem",
					backgroundImage: `url(${TVBg})`,
					backgroundSize: "cover",
				}}
				className="image-default"
			>
				<Container className="container-wrapper">
					<MyBreadcrumb
						isWhiteTheme={true}
						list={["Internet/Truyền hình", "Truyền hình"]}
					/>

					<div className="inter-title fw-bold text-center p-5">
						ĐĂNG KÝ TRUYỀN HÌNH CHO NGÔI NHÀ CỦA BẠN
					</div>
				</Container>
			</div>

			<Container className="container-wrapper mb-5">
				<ListTV listItems={tvs} isLoading={isLoading} />

				<Section
					title={
						<div style={{ color: "var(--white)" }}>GÓI COMBO</div>
					}
				>
					<div className="mb-3">
						Các gói cước tích hợp nhiều dịch vụ trên 01 đường truyền
						internet, đáp ứng tối đa nhu cầu học tập, giải trí, giám
						sát bảo vệ cho cả gia đình...với chi phí hợp lý nhất.
					</div>

					<Carousel
						responsive={responsive3Items}
						autoPlay
						infinite={true}
					>
						{combos.map((item) => {
							return (
								<ComboItem
									key={item.id}
									item={item}
									link="goi-data"
									className="me-4"
								/>
							);
						})}
					</Carousel>
				</Section>
			</Container>

			<BottomBannerInternetTV />
		</div>
	);
};

export default TV;
