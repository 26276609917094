const EnterpriseSection = ({ title, children }) => {
	return (
		<div className="my-4 my-md-5">
			<div className="display-flex-center mb-3">
				<div className="section-title text-center">{title}</div>
			</div>

			{children}
		</div>
	);
};

export default EnterpriseSection;
