import { Col, Row } from "react-bootstrap";
import EnterpriseSection from "./EnterpriseSection";

const ListAdvantages2 = ({ title, items, col }) => {
	return (
		<div
			style={{
				height: "fit-content",
				backgroundImage: `url(https://vietteltelecom.vn/images/sme/smev3/Frame%209505.png)`,
				backgroundSize: "cover",
			}}
			className="image-default p-3 p-md-4"
		>
			<EnterpriseSection title={title}>
				<Row className="mt-3 mt-md-5">
					{items.map((item) => {
						return (
							<Col
								key={item.id}
								xs="6"
								md={12 / col}
								className="mb-3"
							>
								<div className="d-flex justify-content-center">
									<div
										style={{
											height: 68,
											width: 68,
											backgroundImage: `url(${item.icon})`,
											backgroundSize: "cover",
										}}
										className="image-default mb-3"
									/>
								</div>
								<div className="text-center">
									{item.content}
								</div>
							</Col>
						);
					})}
				</Row>
			</EnterpriseSection>
		</div>
	);
};

export default ListAdvantages2;
