import { Link } from "react-router-dom";
import { NEW_EVENT } from "../../routes/routerUrl";
import DateFormat from "../DateFormat";

const RelatedNew = ({ item }) => {
	return (
		<div className="viettel-radius h-100 mx-2">
			<Link
				to={`${NEW_EVENT}/${item.slug}`}
				style={{ textDecoration: "none" }}
			>
				<div
					style={{
						aspectRatio: "1.7/1",
						backgroundImage: `url("${item.image}")`,
						borderBottomRightRadius: 16,
					}}
					className="image-default d-flex align-items-end"
				/>
			</Link>
			<div
				style={{
					backgroundColor: "var(--white)",
					borderTopLeftRadius: 32,
					borderBottomRightRadius: 16,
					marginTop: "-20%",
				}}
				className="w-100 p-3 hover-border"
			>
				<Link
					to={`${NEW_EVENT}/${item.slug}`}
					style={{
						maxHeight: "4rem",
						minHeight: "4rem",
					}}
					className="item-title-hover text-overflow-2-line"
				>
					{item.title}
				</Link>

				<DateFormat className={`mt-2`} />
			</div>
		</div>
	);
};

export default RelatedNew;
