import Carousel from "react-multi-carousel";

import Section from "../../Section";
import Loading from "../../Loading";
import InternetItem from "./InternetItem";
import NoData from "../../NoData";

import {
	responsive4Items,
	responsive3Items,
} from "../../../utils/carouselResponsiveItem";

const ListInternet = ({ title, allLink, isLoading, listItems, colShow }) => {
	return (
		<Section title={title} allLink={allLink}>
			{isLoading ? (
				<Loading />
			) : listItems?.length > 0 ? (
				<Carousel
					responsive={colShow ? responsive4Items : responsive3Items}
					autoPlay
					infinite={true}
				>
					{listItems?.map((item) => {
						return (
							<InternetItem
								key={item.id}
								item={item}
								className="mx-2"
							/>
						);
					})}
				</Carousel>
			) : (
				<NoData />
			)}
		</Section>
	);
};

export default ListInternet;
