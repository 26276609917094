import CurrencyFormat from "react-currency-format";

const MoneyFormat = ({ money, style, className }) => {
	return (
		<CurrencyFormat
			value={money}
			displayType={"text"}
			thousandSeparator={true}
			allowNegative={false}
			isNumericString
			suffix={`đ`}
			style={style}
			className={`${className}`}
		/>
	);
};

export default MoneyFormat;
