import { useEffect } from "react";

const Dashboard = () => {
	useEffect(() => {
		document.title = "Tổng quan";
	}, []);

	return <>Tổng quan</>;
};

export default Dashboard;
