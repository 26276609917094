import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Banner from "../../assets/banner/banner-static.png";

const tabItems = [
	{
		id: 1,
		name: "Danh hiệu",
		html: `<div>
							<div>
								<strong>
									Danh hiệu - Giải thưởng quốc tế
								</strong>
							</div>

							<br />

							<div>
								- Top 15 doanh nghiệp viễn thông phát triển
								nhanh nhất thế giới,
							</div>
							<br />
							<div>
								- Xếp thứ 28 trên top 150 nhà mạng có giá trị
								nhất thế giới, với giá trị thương hiệu đạt 5,8
								tỷ USD, đứng số 1 tại Đông Nam Á và thứ 9 tại
								Châu Á
							</div>
							<br />
							<div>
								- Chứng nhận “Best in Test” từ Công ty đo kiểm
								viễn thông hàng đầu thế giới Umlaut 2020
							</div>
							<br />
							<div>
								- Giải Bạc sản phẩm viễn thông mới xuất sắc nhất
								của giải thưởng Kinh doanh quốc tế 2020 cho gói
								data siêu tốc ST15K
							</div>
							<br />
							<div>
								- Nhà cung cấp dịch vụ của năm tại các thị
								trường đang phát triển năm 2009 và Nhà cung cấp
								dịch vụ data di động tốt nhất Việt Nam – 2019
								(Frost & Sullivan)
							</div>
							<br />
							<div>
								- Giải bạc hạng mục "Dịch vụ khách hàng mới của
								năm“ trong hệ thống giải thưởng quốc tế Stevie
								Awards 2014 cho Dịch vụ tổng đài tiếng dân tộc.
							</div>
							<br />
						</div>`,
	},
	{
		id: 2,
		name: "Thế mạnh",
		html: `<div>
							<div>
								- Gần 120.000 trạm phát sóng 2G- 5G được lắp đặt
								trên toàn quốc kể cả vùng sâu, vùng xa, biên
								giới và hải đảo.
							</div>
							<br />
							<div>
								- Mạng 4G lớn nhất Việt Nam với 45.000 trạm phát
								sóng, độ phủ đạt 97% dân số
							</div>
							<br />
							<div>
								- Hạ tầng cáp quang lớn nhất Việt Nam với hơn
								380.000km;
							</div>
							<br />
							<div>
								- Hạ tầng Gpon lớn nhất Việt Nam với hơn 11
								triệu cổng, cung cấp tới 100% số huyện, huyện
								đảo gần bờ và 95% số xã trên toàn quốc.
							</div>
							<br />
							<div>
								- Tiên phong trong cung cấp dịch vụ 5G.
							</div>
							<br />
						</div>`,
	},
	{
		id: 3,
		name: "Sứ mệnh & Giá trị",
		html: `<div>
							<div>
								<strong>Sứ mệnh: Sáng tạo vì con người- Caring Inovator</strong>
							</div>
							<br />
							<div>
								Mỗi khách hàng là một con người – một cá thể
								riêng biệt, cần được tôn trọng, quan tâm và lắng
								nghe, thấu hiểu và phục vụ một cách riêng biệt.
								Liên tục đổi mới, cùng với khách hàng sáng tạo
								ra các sản phẩm, dịch vụ ngày càng hoàn hảo.
							</div>
							<br />
							<div><strong>Giá trị cốt lõi</strong></div>
							<br />
							<div>
								Những giá trị cốt lõi là lời cam kết của Viettel
								đối với khách hàng, đối tác, các nhà đầu tư, với
								xã hội và với chính bản thân chúng tôi. Những
								giá trị này là kim chỉ nam cho mọi hoạt động của
								Viettel để trở thành một doanh nghiệp kinh doanh
								sáng tạo vì con người.
							</div>
							<br />
							<div>
								1. Thực tiễn là tiêu chuẩn kiểm nghiệm chân lý.
							</div>
							<br />
							<div>
								2. Trưởng thành qua những thách thức và thất
								bại.
							</div>
							<br />
							<div>
								3. Thích ứng nhanh là sức mạnh cạnh tranh.
							</div>
							<br />
							<div>4. Sáng tạo là sức sống.</div>
							<br />
							<div>5. Tư duy hệ thống.</div>
							<br />
							<div>6. Kết hợp Đông - Tây.</div>
							<br />
							<div>
								7. Truyền thống và cách làm người lính.
							</div>
							<br />
							<div>
								8. Viettel là ngôi nhà chung
							</div>
						</div>`,
	},
	{
		id: 4,
		name: "Mục tiêu",
		html: `<div>
							<div>
								- Trở thành doanh nghiệp chủ đạo kiến tạo xã hội
								số tại Việt Nam, đạt doanh thu dịch vụ 100 nghìn
								tỷ vào năm 2025
							</div>
							<br />
							<div>
								- Số một về thị phần di động và cố định băng
								rộng tại Việt Nam
							</div>
							<br />
							<div>
								- Chuyển dịch Viettel Telecom thành một doanh
								nghiệp viễn thông số, có dịch vụ khách hàng và
								trải nghiệm khách hàng số 1 tại Việt Nam
							</div>
							<br />
							<div>
								- Tiên phong về công nghệ 5G, IoT và các hạ tầng
								đáp ứng cơ hội phát triển trong cuộc Cách mạng
								công nghiệp 4.0
							</div>
							<br />
							<div>
								- Hoàn thiện hệ sinh thái sản phẩm dịch vụ số,
								đưa tỷ trọng doanh thu dịch vụ số tương đương
								với các nhà mạng trong khu vực và trên thế giới
							</div>
						</div>`,
	},
];

const Introduction = () => {
	const [selectedTab, setSelectedTab] = useState(1);

	useEffect(() => {
		document.title = "Giới thiệu chung";
	}, []);

	return (
		<>
			<div
				style={{
					width: "100%",
					aspectRatio: "1440/742",
					backgroundSize: "contain",
					backgroundImage: `url(${Banner})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
				}}
			/>
			<Container className="container-wrapper my-5">
				<Row>
					<Col xs="12" md="8">
						<div
							dangerouslySetInnerHTML={{
								__html: tabItems.find(
									(item) => item.id === selectedTab,
								).html,
							}}
						/>
					</Col>
					<Col xs="12" md="4">
						{tabItems.map((item) => {
							return (
								<div
									key={item.id}
									className="mb-3"
									onClick={() => setSelectedTab(item.id)}
								>
									<div
										className={
											item.id === selectedTab
												? "section-tab-active pb-2"
												: "section-tab pb-2"
										}
									>
										{item.name}
									</div>
								</div>
							);
						})}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Introduction;
