import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { Col, Row } from "react-bootstrap";
import Section from "../Section";
import MyPagination from "../MyPagination";
import ClientLoading from "../ClientLoading";

import { NEW_EVENT } from "../../routes/routerUrl";
import NoData from "../NoData";
import DateFormat from "../DateFormat";

const itemsPerPage = 4;

const ListEventNew = ({ allLink, isPagiantion }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [topicOptions, setTopicsOptions] = useState([{ id: 0, name: "" }]);
	const [selectedTab, setSelectedTab] = useState(0);

	useEffect(() => {
		getNewEventsByTopic();
		getAllOptionTopics();
	}, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllOptionTopics = () => {
		axios
			.get(`/topic/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setTopicsOptions(
						[{ id: 0, name: "Tất cả", slug: "tat-ca" }].concat(
							res.data.topics,
						),
					);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getNewEventsByTopic = async (slug) => {
		setIsLoading(true);
		await axios
			.get(`/newEvent/topic/${slug}`, {
				params: {
					page: currentPage,
					limit: itemsPerPage,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data?.errCode === 0) {
					setData({
						newEvents: res.data.newEvents,
						totalCount: res.data.totalCount,
						totalPages: res.data.totalPages,
					});
				} else {
					toast(res.data?.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	const handleClickTab = (item) => {
		getNewEventsByTopic(item.slug);
		setSelectedTab(item.id);
	};

	const RenderLeftItem = ({ item }) => {
		return (
			<Col xs="12" md="6" className="mb-3 p-0">
				<Link to={`${NEW_EVENT}/${item.slug}`}>
					<div
						key={item.id}
						style={{
							aspectRatio: "1.7/1",
							backgroundImage: `url("${item.image}")`,
							backgroundSize: "cover",
						}}
						className="image-default viettel-radius"
					/>
				</Link>

				<div
					style={{
						backgroundColor: "var(--white)",
						borderTopLeftRadius: 32,
						borderBottomRightRadius: 16,
						marginTop: "-2rem",
					}}
					className="w-100 p-3 hover-border"
				>
					<Link
						to={`${NEW_EVENT}/${item.slug}`}
						className="item-title-hover text-overflow-1-line"
					>
						{item.title}
					</Link>
					<div
						style={{
							height: "5rem",
							lineHeight: "1.6rem",
						}}
						className="text-overflow-3-line"
					>
						{item.description}
					</div>
					<DateFormat className={`mt-4`} />
				</div>
			</Col>
		);
	};

	const RenderRigthItem = ({ listItems }) => {
		return (
			<Col xs="12" md="6">
				{listItems.slice(1, listItems.length).map((newItem) => {
					return (
						<Row
							key={newItem.id}
							className="viettel-radius hover-border border mb-2 mx-0"
						>
							<Col className="px-0">
								<Link to={`${NEW_EVENT}/${newItem.slug}`}>
									<div
										style={{
											aspectRatio: "1.65/1",
											backgroundImage: `url("${newItem.image}")`,
											backgroundSize: "cover",
											borderTopLeftRadius: 16,
										}}
										className="image-default"
									/>
								</Link>
							</Col>

							<Col>
								<Link
									to={`${NEW_EVENT}/${newItem.slug}`}
									className="item-title-hover text-overflow-1-line mt-2"
								>
									{newItem.title}
								</Link>
								<div
									style={{
										height: "3rem",
									}}
									className="text-overflow-2-line"
								>
									{newItem.description}
								</div>
								<DateFormat date={newItem?.createdAt} />
							</Col>
						</Row>
					);
				})}
			</Col>
		);
	};

	return (
		<>
			<Section
				title="TIN TỨC SỰ KIỆN"
				tabItems={topicOptions}
				tabOnClick={handleClickTab}
				tabIsActive={selectedTab}
				allLink={allLink}
			>
				{isLoading ? <ClientLoading /> : ""}

				{data.newEvents && data.newEvents.length > 0 ? (
					<Row className="h-100 mx-0">
						<RenderLeftItem item={data?.newEvents[0]} />

						<RenderRigthItem listItems={data?.newEvents} />
					</Row>
				) : (
					<NoData />
				)}
			</Section>

			{data.totalPages > 1 && isPagiantion && (
				<MyPagination
					itemsCount={data.totalCount}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					alwaysShown={false}
				/>
			)}
		</>
	);
};

export default ListEventNew;
