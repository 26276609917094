import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import "./Style.css";

function MasterLayout() {
	const [showSideBar, setShowSideBar] = useState(false);

	return (
		<div style={{ backgroundColor: "#fbfbfb" }}>
			<Navbar setShowSideBar={setShowSideBar} showSideBar={showSideBar} />

			<Sidebar
				setShowSideBar={setShowSideBar}
				showSideBar={showSideBar}
			/>

			<div onClick={() => setShowSideBar(false)}>
				<div
					className={`${showSideBar ? "curtain active" : "curtain"}`}
				/>

				<Outlet />
			</div>

			<Footer />
		</div>
	);
}

export default MasterLayout;
