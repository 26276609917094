import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Col, Container, Row } from "react-bootstrap";

import BottomBannerInternetTV from "../../../components/InternetTV/BottomBannerInternetTV";
import MyBreadcrumb from "../../../components/MyBreadcrumb";
import ClientLoading from "../../../components/ClientLoading";
import ListInternet from "../../../components/InternetTV/Internet/ListInternet";

import BgIt from "../../../assets/banner/bg-it.png";
import BgItSp from "../../../assets/banner/bg-it-sp.png";
import BgPackPlus from "../../../assets/banner/bg-inter-pack-plus.png";

// const responsiveComboItem = {
// 	large: {
// 		breakpoint: { max: 3000, min: 1200 },
// 		items: 3,
// 		slidesToSlide: 1,
// 	},
// 	desktop: {
// 		breakpoint: { max: 1200, min: 768 },
// 		items: 3,
// 		slidesToSlide: 1,
// 	},
// 	tablet: {
// 		breakpoint: { max: 768, min: 576 },
// 		items: 2,
// 		slidesToSlide: 1,
// 	},
// 	mobile: {
// 		breakpoint: { max: 576, min: 0 },
// 		items: 1,
// 		slidesToSlide: 1,
// 	},
// };

const Internet = () => {
	const [topInternets, setTopInternets] = useState([]);
	const [bottomInternets, setBottomInternets] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Internet";
		getAllInternets();
	}, []);

	// API
	const getAllInternets = async () => {
		setIsLoading(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 4,
					keyword: "",
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setTopInternets(
						res.data.services.filter(
							(service) => service.serviceTypeId === 13,
						),
					);
					setBottomInternets(
						res.data.services.filter(
							(service) => service.serviceTypeId === 14,
						),
					);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<>
			<div
				style={{
					aspectRatio: "2878/580",
					backgroundImage: `url(${BgIt})`,
				}}
				className="image-default d-none d-md-block"
			>
				<Container className="container-wrapper">
					<MyBreadcrumb
						isWhiteTheme={true}
						list={["Internet/Truyền hình", "Internet"]}
					/>

					<div className="display-flex-center">
						<div
							style={{
								fontSize: "2rem",
								color: "var(--white)",
								width: "50%",
							}}
							className="text-center fw-bold"
						>
							ĐĂNG KÝ INTERNET CHO NGÔI NHÀ CỦA BẠN
						</div>
					</div>
				</Container>
			</div>

			<div
				style={{
					aspectRatio: "2878/580",
					backgroundImage: `url(${BgItSp})`,
				}}
				className="image-default d-block d-md-none"
			>
				<Container className="container-wrapper">
					<MyBreadcrumb list={["Internet/Truyền hình", "Internet"]} />

					<div
						style={{
							fontSize: "1rem",
							color: "var(--white)",
						}}
						className="text-center fw-bold"
					>
						ĐĂNG KÝ INTERNET CHO NGÔI NHÀ CỦA BẠN
					</div>
				</Container>
			</div>

			<Container className="container-wrapper mb-3">
				{isLoading ? <ClientLoading /> : ""}

				<div
					style={{ fontSize: "1.5rem" }}
					className="item-text fw-bold text-center mb-4"
				>
					CÁC GÓI CƯỚC TỐC ĐỘ CAO, TÍCH HỢP GIẢI PHÁP MESH WIFI
				</div>

				<ListInternet listItems={topInternets} colShow={true} />
			</Container>

			<div
				style={{
					aspectRatio: "1439/668",
					backgroundImage: `url(${BgPackPlus})`,
				}}
				className="image-default d-flex align-items-center"
			>
				<Container className="container-wrapper">
					<div
						style={{ color: "white", fontSize: "1.5rem" }}
						className="item-text fw-bold text-center mb-4"
					>
						GÓI CƯỚC TỐC ĐỘ CAO
					</div>

					<Row>
						<Col xs="12" md="9">
							<ListInternet listItems={bottomInternets} />
						</Col>
						<Col xs="12" md="3" />
					</Row>
				</Container>
			</div>

			<BottomBannerInternetTV />
		</>
	);
};

export default Internet;
