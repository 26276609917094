import { Col, Container, Row } from "react-bootstrap";
import EnterpriseIntro from "../../../components/Enterprise/EnterpriseIntro";
import EnterpriseSection from "../../../components/Enterprise/EnterpriseSection";
import { packages } from "../../../InitialData";
import ListWhys from "../../../components/Enterprise/ListWhys";
import ListAdvantages from "../../../components/Enterprise/ListAdvantage";
import ListSell from "../../../components/Enterprise/ListSell";

const whyItems = [
	{
		id: 1,
		icon: "https://viettel.vn/images/sme/home/vt-ca-1.jpg",
		content:
			"Thương hiệu uy tín, sự lựa chọn hàng đầu của các doanh nghiệp, tổ chức.",
	},
	{
		id: 2,
		icon: "https://viettel.vn/images/sme/home/vt-ca-2.jpg",
		content:
			"Tự hào là nhà cung cấp dịch vụ Chứng thực Chữ ký số hàng đầu Việt Nam.",
	},
	{
		id: 3,
		icon: "https://viettel.vn/images/sme/home/vt-ca-3.jpg",
		content:
			"Hệ sinh thái các sản phẩm dịch vụ đa dạng đáp ứng mọi nhu cầu của Doanh nghiệp.",
	},
	{
		id: 4,
		icon: "https://viettel.vn/images/sme/home/vt-ca-4.jpg",
		content: "Hỗ trợ tại 63 Tỉnh/TP.",
	},
];

const featureItems = [
	{
		id: 1,
		icon: "https://vietteltelecom.vn/images/sme/home/ca-feature-1-1.png",
		note: "Luật Giao dịch điện tử 2005 51/2005/QH11 và Nghị định 130/2018/NĐ-CP",
		bgImage: "https://vietteltelecom.vn/images/sme/home/ca-feature-1.png",
		bgColor: "#f8b5a4",
		content: "Công nghệ duy nhất có tính pháp lý, được pháp luật công nhận",
	},
	{
		id: 2,
		icon: "https://vietteltelecom.vn/images/sme/home/ca-feature-2-2.png",
		note: "Áp dụng các công nghệ mới nhất. Được phát triển bởi đội ngũ kỹ sư CNTT giỏi nhất. Hệ thống có nhiều lớp bảo mật cao cấp.",
		bgImage: "https://vietteltelecom.vn/images/sme/home/ca-feature-2.png",
		bgColor: "#ee0033",
		content: "Đạt tiêu chuẩn quốc tế về bảo mật",
	},
	{
		id: 3,
		icon: "https://vietteltelecom.vn/images/sme/home/ca-feature-3-3.png",
		note: "Tích hợp vào nhiều dịch vụ, giao dịch điện tử: Kê khai thuế điện tử, Hải quan điện tử, Hóa đơn điện tử, BHXH điện tử...",
		bgImage: "https://vietteltelecom.vn/images/sme/home/ca-feature-3.png",
		bgColor: "#a80024",
		content: "Hỗ trợ nhiều giao dịch điện tử",
	},
	{
		id: 4,
		icon: "https://vietteltelecom.vn/images/sme/home/ca-feature-4-4.png",
		note: "",
		bgImage: "https://vietteltelecom.vn/images/sme/home/ca-feature-4.png",
		bgColor: "#5c1c29",
		content:
			"Giao dịch điện tử được đảm bảo an toàn tuyệt đối, thực hiện nhanh chóng.",
	},
];

const advantageItems = [
	{
		id: 1,
		icon: "https://viettel.vn/images/sme/home/ca-strong-1.png",
		content:
			"Bên cạnh ký số bằng USB Token truyền thống, Viettel- CA có thể ký trên mọi thiết bị di động, chỉ cần có sóng Viettel.",
	},
	{
		id: 2,
		icon: "https://viettel.vn/images/sme/home/ca-strong-2.png",
		content:
			"Mạng lưới kênh bán rộng khắp, đội ngũ hỗ trợ đông đảo, tổng đài CSKH 24/7.",
	},
	{
		id: 3,
		icon: "https://viettel.vn/images/sme/home/ca-strong-3.png",
		content: "Quy trình cấp phát chứng thư số nhanh nhất: Trong vòng 24h.",
	},
	{
		id: 4,
		icon: "https://viettel.vn/images/sme/home/ca-strong-4.png",
		content: "Kết nối với nhiều phần mềm nghiệp vụ.",
	},
];

const ViettelCA = () => {
	return (
		<>
			<Container className="container-wrapper">
				<Row className="py-5">
					<Col xs="12" md="4">
						<div
							style={{
								aspectRatio: 1,
								backgroundImage: `url(https://viettel.vn/images/sme/home/icon-ca-sign.png)`,
							}}
							className="image-default top-radius-8 text-uppercase"
						/>
					</Col>
					<Col xs="12" md="8">
						<EnterpriseIntro
							title="CHỮ KÝ SỐ VIETTEL CA LÀ GÌ?"
							description={
								"Viettel CA - Chữ ký số Viettel (hay còn gọi là Chứng thư số Viettel) là dịch vụ giải pháp hiện đại an toàn và bảo mật, toàn bộ thông tin dữ liệu được xử lý mã hóa và đảm bảo tính đầy đủ của doanh nghiệp. Chữ ký số dùng để ký số hóa điện tử thay cho chữ ký tay thông thường trên các loại văn bản hoặc tài liệu số thông qua mạng Internet."
							}
							buttonClass={`justify-content-start`}
						/>
					</Col>
				</Row>

				{/* Why */}
				<ListWhys
					title={`TẠI SAO PHẢI LỰA CHỌN VIETTEL CA?`}
					items={whyItems}
				/>
			</Container>

			{/* Feature */}
			<EnterpriseSection title="TÍNH NĂNG SẢN PHẨM">
				<Row className="mx-0">
					{featureItems.map((item) => {
						return (
							<Col
								xs="12"
								xl="6"
								key={item.id}
								style={{
									aspectRatio: "3/1",
									backgroundColor: item.bgColor,
									backgroundImage: `url(${item.bgImage})`,
								}}
								className="image-default d-flex align-items-center p-3"
							>
								<div
									key={item.id}
									className="d-flex align-items-center"
								>
									<div
										style={{
											color: "var(--white)",
											backgroundColor: "white",
											borderRadius: 16,
											height: "fit-content",
										}}
										className="me-3 p-3"
									>
										<div
											style={{
												color: "var(--white)",
												backgroundImage: `url(${item.icon})`,
											}}
											className="image-default enterprise-feature-icon"
										/>
									</div>
									<div>
										<div
											style={{
												color: "white",
												fontSize: "1.5rem",
												width: "60%",
											}}
											className="text-overflow-2-line"
										>
											{item.content}
										</div>

										<div
											style={{
												color: "white",
												fontSize: "0.8rem",
												width: "60%",
												fontStyle: "italic",
											}}
										>
											{item.note}
										</div>
									</div>
								</div>
							</Col>
						);
					})}
				</Row>
			</EnterpriseSection>

			<Container className="container-wrapper">
				{/* Advantage */}
				<ListAdvantages
					title="ƯU ĐIỂM VƯỢT TRỘI"
					items={advantageItems}
					col={4}
				/>

				{/* Sell */}
				<ListSell title="BẢNG GIÁ/ CHÍNH SÁCH" items={packages} />
			</Container>
		</>
	);
};

export default ViettelCA;
