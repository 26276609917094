import { useEffect, useState } from "react";
import axios from "axios";
import Carousel from "react-multi-carousel";
import { toast } from "react-toastify";

import Loading from "../Loading";
import RelatedNew from "./RelatedNew";
import NoData from "../NoData";

import { responsive3Items } from "../../utils/carouselResponsiveItem";

const ListEventNewBottom = () => {
	const [newEvents, setNewEvents] = useState([]);
	const [topicOptions, setTopicsOptions] = useState([{ id: 0, name: "" }]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getNewEventsByTopic();
		getAllOptionTopics();
	}, []);

	const getAllOptionTopics = () => {
		axios
			.get(`/topic/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setTopicsOptions(
						[{ id: 0, name: "Tất cả", slug: "tat-ca" }].concat(
							res.data.topics,
						),
					);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getNewEventsByTopic = async (slug) => {
		setIsLoading(true);
		await axios
			.get(`/newEvent/topic/${slug}`, {
				params: {
					limit: 10,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setNewEvents(res.data.newEvents);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			{topicOptions?.length > 0 ? (
				<div className="display-flex-center my-3">
					{topicOptions?.map((tabItem) => {
						return (
							<div
								key={tabItem.id}
								className="section-tab"
								onClick={() => {
									getNewEventsByTopic(tabItem.slug);
								}}
							>
								{tabItem.name}
							</div>
						);
					})}
				</div>
			) : (
				""
			)}

			{isLoading ? (
				<Loading />
			) : newEvents && newEvents?.length > 0 ? (
				<Carousel
					responsive={responsive3Items}
					autoPlay
					infinite={true}
				>
					{newEvents?.map((newItem) => {
						return <RelatedNew key={newItem.id} item={newItem} />;
					})}
				</Carousel>
			) : (
				<NoData />
			)}
		</>
	);
};

export default ListEventNewBottom;
