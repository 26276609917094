import Carousel from "react-multi-carousel";

import Section from "../../Section";
import Loading from "../../Loading";
import NoData from "../../NoData";

import { responsive2Items } from "../../../utils/carouselResponsiveItem";
import TVItem from "./TVItem";

const ListTV = ({ title, allLink, isLoading, listItems }) => {
	return (
		<Section title={title} allLink={allLink}>
			{isLoading ? (
				<Loading />
			) : listItems?.length > 0 ? (
				<Carousel
					responsive={responsive2Items}
					autoPlay
					infinite={true}
				>
					{listItems?.map((item) => {
						return (
							<TVItem
								key={item.id}
								item={item}
								className="mx-2"
							/>
						);
					})}
				</Carousel>
			) : (
				<NoData />
			)}
		</Section>
	);
};

export default ListTV;
