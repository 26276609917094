import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Container } from "react-bootstrap";
import { INTERNET_TV, MOBILE, NEW_EVENT } from "../../routes/routerUrl";

import CarouselBanner from "../../components/CarouselBanner";
import Section from "../../components/Section";
import ListEventNew from "../../components/EventNew/ListEventNew";
import ListMainPackage from "../../components/Mobile/MainPackage/ListMainPackage";
import ListDataPackage from "../../components/Mobile/DataPackage/ListDataPackage";
import ListInternetTVHome from "../../components/InternetTV/ListInternetTVHome";

import "react-multi-carousel/lib/styles.css";
import "./Style.css";

const Home = () => {
	const [dataPackages, setDataPackages] = useState([]);
	const [mainPackages, setMainPackages] = useState([]);
	const [selectedMobile, setSelectedMobile] = useState(1);

	useEffect(() => {
		document.title = "Trang chủ";
		getAllDataPackages();
		getAllMainPackages();
	}, []);

	// API
	const getAllDataPackages = async () => {
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 1,
					keyword: "",
					limit: 10,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setDataPackages(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllMainPackages = async () => {
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 3,
					keyword: "",
					limit: 10,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setMainPackages(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	const handleClickTabMobile = (item) => {
		setSelectedMobile(item.id);
	};

	return (
		<>
			<CarouselBanner />

			<Container className="container-wrapper">
				{/* New Event */}
				<ListEventNew allLink={NEW_EVENT} isPagiantion={false} />

				<div className="py-3" />

				{/* Mobile Service */}
				<Section
					title="Dịch vụ di động"
					tabItems={[
						{
							id: 1,
							name: "Gói data",
						},
						{
							id: 2,
							name: "Gói cước chính",
						},
					]}
					tabOnClick={handleClickTabMobile}
					tabIsActive={selectedMobile}
					allLink={`${
						selectedMobile === 1
							? `${MOBILE}/goi-data`
							: `${MOBILE}/goi-cuoc`
					}`}
				>
					{selectedMobile === 1 ? (
						<ListDataPackage listItems={dataPackages} />
					) : (
						<ListMainPackage listItems={mainPackages} />
					)}
				</Section>

				<div className="py-3" />

				{/* Internet - TV */}
				<ListInternetTVHome
					title={`Internet - Truyền hình`}
					allLink={INTERNET_TV}
					listItems={[]}
				/>

				<div className="py-3" />
			</Container>
		</>
	);
};

export default Home;
