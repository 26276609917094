import { Button, Col, Row } from "react-bootstrap";

const BottomBannerEnterprise = ({ className, title, content }) => {
	return (
		<div
			style={{
				backgroundImage: `url(https://vietteltelecom.vn/images/sme/smev3/bannerpc.png)`,
			}}
			className={`enterprise-botton-img-bg image-default p-5 mb-5 ${className}`}
		>
			<Row>
				<Col xs="12" md="9" style={{ color: "white" }}>
					<div className="item-title">{title}</div>
					<div className="item-text my-3">{content}</div>

					<Button
						style={{ backgroundColor: "white" }}
						className="white-button px-4"
					>
						Đăng ký ngay
					</Button>
				</Col>
				<Col xs="12" md="3"></Col>
			</Row>
		</div>
	);
};

export default BottomBannerEnterprise;
