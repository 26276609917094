import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";

import TextEditor from "../../../../components/TextEditor";
import convertToSlug from "../../../../utils/slugFormatter";

const addNewEventFormItems = [
	{
		id: 1,
		placeholder: "Tiêu đề",
		name: "title",
	},
	{
		id: 2,
		placeholder: "Mô tả",
		name: "description",
	},
];

const NewEventAddModal = ({ handleCloseModal, showModal }) => {
	const [slug, setSlug] = useState("");
	const [newEventImage, setNewEventImage] = useState("");
	const [content, setContent] = useState("");

	const [selectedTopic, setSelectedTopic] = useState();
	const [topicOptions, setTopicsOptions] = useState([{ id: 0, name: "" }]);

	const [isSendForm, setIsSendForm] = useState(false);

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		getAllOptionTopics();
	}, []);

	// Api
	const getAllOptionTopics = () => {
		axios
			.get(`/topic/list`)
			.then((res) => {
				if (res.data.errCode === 0) {
					setTopicsOptions(res.data.topics);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const createNewEvent = async (data) => {
		setIsSendForm(true);

		await axios
			.post(`/newEvent/create`, {
				topicId: selectedTopic.id,
				title: data.title,
				description: data.description,
				content: content,
				slug: slug,
				image: newEventImage.split(",")[1],
			})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleUploadImage = (event) => {
		try {
			if (
				!event.target.files[0].type.match(/image.*/) ||
				event.target.files[0].size > 2097152
			) {
				toast("", {
					type: "error",
					autoClose: 1000,
				});

				return;
			}
			let reader = new FileReader();
			reader.onload = function (e) {
				setNewEventImage(e.target.result);
			};
			reader.readAsDataURL(event.target.files[0]);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Modal
			size="lg"
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Thêm mới tin tức sự kiện</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="mb-3">
					<Form.Label>Chọn chủ đề</Form.Label>

					<Select
						options={topicOptions}
						placeholder={`Chọn chủ đề`}
						noOptionsMessage={() => "Không có lựa chọn"}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id}
						value={selectedTopic}
						onChange={(choice) => {
							setSelectedTopic(choice);
						}}
						menuPortalTarget={document.body}
						styles={{
							menuPortal: (base) => ({ ...base, zIndex: 9999 }),
						}}
					/>
				</div>

				<Form
					id="add-new-event-hook-form"
					onSubmit={handleSubmit((data) => {
						if (!newEventImage) {
							toast("Vui lòng chọn file ảnh", {
								type: "error",
								autoClose: 1000,
							});
							return;
						}

						if (!selectedTopic) {
							toast("Vui lòng chọn chủ đề", {
								type: "error",
								autoClose: 1000,
							});
							return;
						}

						createNewEvent(data);
					})}
					className="mb-5"
				>
					{addNewEventFormItems.map((formItem) => {
						if (formItem.id === 2) {
							return (
								<div key={formItem.id} className="mb-3">
									<Form.Control
										placeholder={formItem.placeholder}
										name={formItem.name}
										{...register(formItem.name)}
										as={`textarea`}
										rows={5}
									/>

									{errors[formItem.name] && (
										<p
											style={{
												fontSize: 13,
												color: "red",
												marginTop: 6,
											}}
										>
											{errors[formItem.name].message}
										</p>
									)}
								</div>
							);
						}

						return (
							<div key={formItem.id} className="mb-3">
								<Form.Floating>
									<Form.Control
										type="text"
										placeholder={formItem.placeholder}
										name={formItem.name}
										{...register(formItem.name, {
											onChange: () => {
												setSlug(
													convertToSlug(
														getValues("title"),
													),
												);
											},
										})}
									/>

									<Form.Label>
										{formItem.placeholder}
										<i className="text-danger">*</i>
									</Form.Label>
								</Form.Floating>

								{errors[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errors[formItem.name].message}
									</p>
								)}
							</div>
						);
					})}

					{/* Content */}
					<div className="mb-3">
						<Form.Label>Nội dung</Form.Label>
						<TextEditor
							setContents={content}
							onChange={(value) => setContent(value)}
							placeholder={"Nội dung"}
						/>
					</div>

					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder="Slug"
								name="slug"
								disabled
								value={slug}
							/>

							<Form.Label>
								Slug
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>
					</div>
				</Form>

				{/* Images */}
				<div>
					{newEventImage ? (
						<div
							style={{
								aspectRatio: "625/351",
								backgroundImage: `url("${newEventImage}")`,
								backgroundSize: "cover",
							}}
							className="image-default position-relative mb-3"
						/>
					) : (
						""
					)}

					<input
						type="file"
						accept="image/x-png,image/gif,image/jpeg"
						onChange={(e) => handleUploadImage(e)}
						required
					/>
					<div>
						<div className="d-inline fw-bold">Note: </div>
						Chọn ảnh với tỉ lệ 625:351 để được chất lượng tốt nhất.
					</div>
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					type="submit"
					form="add-new-event-hook-form"
					variant="primary"
					disabled={isSendForm}
				>
					Lưu
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	title: yup
		.string()
		.required("Không được bỏ trống")
		.test("len", "Tiêu đề không quá 255 kí tự", (val) => val.length <= 255),
});

export default NewEventAddModal;
