import { Button } from "react-bootstrap";

const EnterpriseIntro = ({
	title,
	description,
	className,
	style,
	buttonClass,
}) => {
	return (
		<div style={style} className={className}>
			<div className="enterprise-intro-title">{title}</div>
			<div className="enterprise-intro-text my-3 my-md-4">
				{description}
			</div>

			<div className={`d-flex ${buttonClass}`}>
				<Button className="enterprise-intro-mute-button me-3">
					<div
						style={{
							marginTop: "-1.3rem",

							fontSize: "0.9rem",
						}}
						className="d-flex justify-content-center"
					>
						<div
							style={{
								width: "fit-content",
								backgroundColor: "var(--white)",
							}}
							className="px-2"
						>
							Liên hệ
						</div>
					</div>
					<div>18008168</div>
				</Button>
				<Button className="enterprise-intro-button">
					Đăng ký ngay
				</Button>
			</div>
		</div>
	);
};

export default EnterpriseIntro;
