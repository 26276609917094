import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { Col, Container, Row } from "react-bootstrap";
import MyBreadcrumb from "../../../../components/MyBreadcrumb";
import MoneyFormat from "../../../../components/MoneyFormat";
import ClientLoading from "../../../../components/ClientLoading";

import IconSK from "../../../../assets/icon/icon-sk.png";
import IconGR from "../../../../assets/icon/icon-gr.png";
import IconMLN from "../../../../assets/icon/icon-mln.png";
import IconCSTN from "../../../../assets/icon/icon-cstn.png";

import Logo from "../../../../assets/logo/favicon.png";
import ListMainPackage from "../../../../components/Mobile/MainPackage/ListMainPackage";

const mainPackageItems = [
	{
		id: 1,
		label: "Sống khỏe",
		icon: IconSK,
	},
	{
		id: 2,
		label: "Giá rẻ",
		icon: IconGR,
	},
	{
		id: 3,
		label: "Cộng đồng lớn nhất",
		icon: IconMLN,
	},
	{
		id: 4,
		label: "Chăm sóc khách hàng tốt",
		icon: IconCSTN,
	},
];

const MainPackageDetail = () => {
	let { slug } = useParams();

	const [mainPackage, setMainPackage] = useState({});
	const [relatedMainPackage, setRelatedMainPackage] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Chi tiết gói cước";

		getDetailDataPackage(slug);
	}, [slug]);

	const getDetailDataPackage = (mainPackageSlug) => {
		setIsLoading(true);
		axios
			.get(`/client/service/${mainPackageSlug}`)
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setMainPackage(res.data.service);
					setRelatedMainPackage(res.data.relatedServices);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container className="container-wrapper">
			<MyBreadcrumb
				list={[
					"Dịch vụ di động",
					"Gói cước chính",
					"Chi tiết gói cước",
				]}
			/>

			{isLoading ? <ClientLoading /> : ""}

			<Row className="justify-content-center">
				<Col
					xs="12"
					xl="9"
					style={{ backgroundColor: "var(--white)" }}
					className="px-4 px-md-5 py-4"
				>
					<Row>
						<Col xs="12" md="3" className="h-100">
							<div
								style={{
									width: "4rem",
									height: "4rem",
									backgroundImage: `url(${Logo})`,
								}}
								className="image-default"
							/>
						</Col>
						<Col xs="12" md="9" className="h-100">
							<div className="item-title">{mainPackage.name}</div>
							<div
								style={{
									color: "var(--primary-color)",
								}}
								className="item-title"
							>
								<MoneyFormat money={mainPackage.price} /> /
								{mainPackage.period}
							</div>

							<Row>
								{mainPackageItems.map((item) => {
									return (
										<Col
											key={item.id}
											className="text-center my-3"
										>
											<div className="display-flex-center p-2">
												<div
													style={{
														backgroundColor:
															"var(--mute-bg-color)",
														borderRadius: "50%",
														width: "3rem",
														height: "3rem",
													}}
													className="display-flex-center p-2"
												>
													<div
														style={{
															width: "2rem",
															height: "2rem",
															backgroundImage: `url(${item.icon})`,
														}}
														className="image-default"
													/>
												</div>
											</div>
											{item.label}
										</Col>
									);
								})}
							</Row>
						</Col>
					</Row>

					<div className="fw-bold">Giới thiệu</div>
					<div>
						<div
							dangerouslySetInnerHTML={{
								__html: mainPackage.content,
							}}
						/>
					</div>
				</Col>
			</Row>

			{/* <Section title="CÁC GÓI TƯƠNG TỰ">
				<Carousel responsive={responsiveItem} autoPlay infinite={true}>
					{relatedMainPackage.map((item) => {
						return (
							<MainPackageItem
								key={item.id}
								item={item}
								link="goi-cuoc"
								className="mx-2"
							/>
						);
					})}
				</Carousel>
			</Section> */}

			<ListMainPackage
				title="CÁC GÓI TƯƠNG TỰ"
				itemLink="goi-cuoc"
				listItems={relatedMainPackage}
				isLoading={false}
			/>
		</Container>
	);
};

export default MainPackageDetail;
