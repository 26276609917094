import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../components/Icon";

const sidebarItems = [
	{
		id: 2,
		navId: "newEvent",
		label: "Tin tức sự kiện",
		icon: "newspaper",
		link: "#",
		subItems: [
			{
				id: 1,
				label: "Chủ đề",
				icon: "book-open-reader",
				link: "topic",
			},
			{
				id: 2,
				label: "Tin tức",
				icon: "newspaper",
				link: "new",
			},
		],
	},
	{
		id: 3,
		navId: "mobile",
		label: "Di động",
		icon: "mobile-screen",
		link: "#",
		subItems: [
			{
				id: 1,
				label: "Gói cước data",
				icon: "database",
				link: "dataPackage",
			},
			// {
			// 	id: 2,
			// 	label: "Gói cước SMS",
			// 	icon: "comment-sms",
			// 	link: "#",
			// },
			{
				id: 3,
				label: "Gói cước chính",
				icon: "server",
				link: "mainPackage",
			},
		],
	},
	{
		id: 4,
		navId: "internetTV",
		label: "Internet / Truyền hình",
		icon: "tags",
		link: "#",
		subItems: [
			{
				id: 1,
				label: "Internet",
				icon: "globe",
				link: "internet",
			},
			{
				id: 2,
				label: "Truyền hình",
				icon: "satellite-dish",
				link: "tv",
			},
			{
				id: 3,
				label: "Combo",
				icon: "ruler-combined",
				link: "comboInternetTV",
			},
		],
	},
	// {
	// 	id: 5,
	// 	navId: "enterprise",
	// 	label: "Doanh nghiệp",
	// 	icon: "industry",
	// 	link: "#",
	// 	subItems: [
	// 		{
	// 			id: 1,
	// 			label: "ViettelCA",
	// 			icon: "tachograph-digital",
	// 			link: "#",
	// 		},
	// 		{
	// 			id: 2,
	// 			label: "SInvoice",
	// 			icon: "receipt",
	// 			link: "#",
	// 		},
	// 		{
	// 			id: 3,
	// 			label: "vContract",
	// 			icon: "file-contract",
	// 			link: "#",
	// 		},
	// 		{
	// 			id: 4,
	// 			label: "vMark",
	// 			icon: "bookmark",
	// 			link: "#",
	// 		},
	// 		{
	// 			id: 5,
	// 			label: "MySign",
	// 			icon: "signature",
	// 			link: "#",
	// 		},
	// 	],
	// },
];

const Sidebar = () => {
	return (
		<nav
			className="sb-sidenav accordion sb-sidenav-light"
			id="sidenavAccordion"
		>
			<div className="sb-sidenav-menu">
				<div className="nav">
					<Link to={`/admin`} className="nav-link">
						<div className="sb-nav-link-icon me-2">
							<Icon icon="gauge" />
						</div>
						Tổng quan
					</Link>

					{sidebarItems.map((item) => {
						return (
							<React.Fragment key={item.id}>
								<Link
									className="nav-link collapsed"
									to={item.link}
									data-bs-toggle="collapse"
									data-bs-target={`#${item.navId}`}
									aria-expanded="false"
									aria-controls={item.navId}
								>
									<div className="sb-nav-link-icon">
										<Icon icon={item.icon} />
									</div>
									{item.label}
									<div className="sb-sidenav-collapse-arrow">
										<Icon icon="chevron-down" />
									</div>
								</Link>
								<div
									className="collapse"
									id={item.navId}
									aria-labelledby="headingOne"
								>
									<nav className="sb-sidenav-menu-nested nav">
										{item.subItems.map((subItem) => {
											return (
												<Link
													key={subItem.id}
													className="nav-link"
													to={subItem.link}
												>
													<div className="sb-nav-link-icon">
														<Icon
															icon={subItem.icon}
														/>
													</div>
													{subItem.label}
												</Link>
											);
										})}
									</nav>
								</div>
							</React.Fragment>
						);
					})}

					{/* <Link to={`/setting`} className="nav-link">
						<div className="sb-nav-link-icon me-2">
							<Icon icon="gear" />
						</div>
						Cài đặt
					</Link> */}
				</div>
			</div>
			<div className="sb-sidenav-footer py-2">
				<div className="small">Version: 1.0</div>
			</div>
		</nav>
	);
};

export default Sidebar;
