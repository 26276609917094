import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import swal from "sweetalert";

import { Breadcrumb, Button, Container, Form } from "react-bootstrap";
import Icon from "../../../../components/Icon";
import DataTables from "../../../../components/DataTables";
import MoneyFormat from "../../../../components/MoneyFormat";
import Loading from "../../../../components/Loading";

import InternetAddModal from "./InternetAddModal";
import InternetEditModal from "./InternetEditModal";

const Internet = () => {
	const [internets, setInternets] = useState([]);
	const [keyword, setKeyword] = useState("");

	const [selectedInternet, setSelectedInternet] = useState(0);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Internet";
		getAllInternets("");
	}, []);

	// API
	const getAllInternets = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 4,
					keyword: keyword,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setInternets(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteInternet = async (internetsIdId) => {
		await axios
			.delete(`/service/delete/${internetsIdId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getAllInternets("");
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteInternet = (internetId) => {
		swal({
			title: `Bạn muốn xóa gói internet này`,
			text: ` Lưu ý: Hành động không thể khôi phục lại`,
			icon: "warning",
			buttons: ["Đóng", "Xóa"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteInternet(internetId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllInternets("");
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
		setSelectedInternet();
	};

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllInternets("");
		}
	};
	const handleShowEditModal = (internetId) => {
		setShowEditModal(true);
		setSelectedInternet(internetId);
	};

	const columns = [
		{
			name: "Tên gói",
			selector: (row) => row.name,
			wrap: true,
		},
		{
			name: "Giá gói",
			selector: (row) => <MoneyFormat money={row.price} />,
			wrap: true,
		},
		{
			name: "Thời gian",
			selector: (row) => row.period,
			wrap: true,
		},
		{
			name: "Loại gói",
			selector: (row) => row.serviceType.name,
			wrap: true,
		},
		{
			name: "Cập nhật",
			selector: (row) => moment(row.updatedAt).format("DD/MM/YYYY"),
		},
		{
			name: "Phương thức",
			selector: (row) => (
				<>
					<Button
						size="sm"
						variant="warning me-2"
						type="button"
						onClick={() => handleShowEditModal(row.id)}
					>
						<Icon icon="pencil" />
					</Button>

					<Button
						size="sm"
						variant="danger"
						type="button"
						onClick={() => handleDeleteInternet(row.id)}
					>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			<Breadcrumb className="mt-2">
				<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
				<Breadcrumb.Item active>Internet / Truyền hình</Breadcrumb.Item>
				<Breadcrumb.Item active>Internet</Breadcrumb.Item>
			</Breadcrumb>

			<div className="d-block d-md-flex justify-content-between mb-3">
				<div>
					<div className="d-flex">
						<Form.Control
							type="search"
							placeholder={"Nhập tên gói"}
							className="me-2"
							aria-label="Search"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<Button
							variant="outline-secondary"
							onClick={() => getAllInternets(keyword)}
						>
							<Icon icon="magnifying-glass" />
						</Button>
					</div>
				</div>
				<div className="mt-3 mt-md-0">
					<Button
						variant="outline-primary"
						className="mt-2 mt-md-0"
						onClick={() => handleShowAddModal()}
					>
						<Icon icon="plus" /> {"Thêm mới"}
					</Button>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={internets} columns={columns} />
			)}

			{showAddModal ? (
				<InternetAddModal
					handleCloseModal={handleCloseAddModal}
					showModal={showAddModal}
				/>
			) : (
				""
			)}

			{showEditModal ? (
				<InternetEditModal
					handleCloseModal={handleCloseEditModal}
					showModal={showEditModal}
					itemId={selectedInternet}
				/>
			) : (
				""
			)}
		</Container>
	);
};

export default Internet;
