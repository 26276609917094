const ClientLoading = () => {
	return (
		<div
			style={{
				zIndex: 9999,
				position: "fixed",
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				opacity: 0.5,
				backgroundColor: "black",
				transition: "850ms",
			}}
			className="display-flex-center"
		>
			<div
				className="spinner-border"
				style={{
					width: "3rem",
					height: "3rem",
					color: "var(--primary-color)",
				}}
				role="status"
			>
				<span className="sr-only">Đang tải</span>
			</div>
		</div>
	);
};

export default ClientLoading;
