import { useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import TextEditor from "../../../../components/TextEditor";
import CurrencyFormat from "react-currency-format";
import Select from "react-select";

import convertToSlug from "../../../../utils/slugFormatter";

const ComboInternetTVAddModal = ({ handleCloseModal, showModal }) => {
	const [slug, setSlug] = useState("");
	const [content, setContent] = useState("");
	const [isSendForm, setIsSendForm] = useState(false);

	const [selectedServiceType, setSelectedServiceType] = useState();
	const [serviceTypeOptions, setServiceTypeOptions] = useState([
		{ id: 0, name: "" },
	]);

	const [errMessage, setErrMessage] = useState({
		slug: undefined,
	});

	const {
		control,
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	useEffect(() => {
		getAllOptionServiceTypes();
	}, []);

	// Api
	const getAllOptionServiceTypes = () => {
		axios
			.get(`/serviceType/list`, {
				params: {
					serviceTypeId: 6,
				},
			})
			.then((res) => {
				if (res.data.errCode === 0) {
					setServiceTypeOptions(res.data.serviceTypes);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const createComboInternetTV = async (data) => {
		setIsSendForm(true);

		await axios
			.post(`/service/create`, {
				serviceTypeId: selectedServiceType.id,
				name: data.name,
				slug: slug,
				price: data.price
					? Number(data.price.replace(/,/g, "").replace("VND", ""))
					: null,
				period: data.period,
				speed: data.speed,
				applyFor: data.applyFor,
				suitable: data.suitable,
				description: data.description,
				content: content,
			})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					setErrMessage(res.data.errMessage);
					toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	return (
		<Modal
			size="lg"
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>
					Thêm mới gói Combo Internet / Truyền hình
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="add-comboInternetTV-hook-form"
					onSubmit={handleSubmit((data) => {
						if (!selectedServiceType) {
							toast("Vui lòng chọn loại gói", {
								type: "error",
								autoClose: 1000,
							});
							return;
						}

						createComboInternetTV(data);
					})}
					className="mb-5"
				>
					<div className="mb-3">
						<Form.Label>Chọn loại cước</Form.Label>

						<Select
							options={serviceTypeOptions}
							placeholder={<div>Chọn loại gói cước</div>}
							noOptionsMessage={() => "Không có lựa chọn"}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
							value={selectedServiceType}
							onChange={(choice) => {
								setSelectedServiceType(choice);
							}}
							menuPortalTarget={document.body}
							styles={{
								menuPortal: (base) => ({
									...base,
									zIndex: 9999,
								}),
							}}
						/>
					</div>

					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder={"Tên gói"}
								name={"name"}
								{...register("name", {
									onChange: () => {
										setSlug(
											convertToSlug(getValues("name")),
										);
									},
								})}
							/>

							<Form.Label>
								{"Tên gói"}
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errors["name"] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors["name"].message}
							</p>
						)}
					</div>

					<div className="mb-3">
						<Form.Control
							placeholder={"Mô tả"}
							name={"description"}
							{...register("description")}
							as={`textarea`}
							rows={5}
						/>

						{errors["description"] && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errors["description"].message}
							</p>
						)}
					</div>

					<Row className="mb-3">
						<Col xs="12" md="4" className="mb-3 mb-md-0">
							<Form.Floating>
								<Controller
									control={control}
									name="price"
									render={({
										field: { onChange, name, value },
									}) => (
										<CurrencyFormat
											className="form-control text-end"
											name={name}
											value={value}
											thousandSeparator={true}
											onChange={onChange}
											suffix=" VND"
											allowNegative={false}
										/>
									)}
								/>

								<Form.Label>
									Giá (VND)
									<i className="text-danger">*</i>
								</Form.Label>
							</Form.Floating>
							{errors.price && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errors.price.message}
								</p>
							)}
						</Col>
						<Col xs="12" md="4">
							<Form.Floating>
								<Form.Control
									type="text"
									placeholder={`Thời gian hiệu lực`}
									name={`period`}
									{...register(`period`)}
								/>

								<Form.Label>
									{`Thời gian hiệu lực`}
									<i className="text-danger">*</i>
								</Form.Label>
							</Form.Floating>
							{errors.period && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errors.period.message}
								</p>
							)}
						</Col>
						<Col xs="12" md="4">
							<Form.Floating>
								<Form.Control
									type="text"
									placeholder={`Tốc độ`}
									name={`speed`}
									{...register(`speed`)}
								/>

								<Form.Label>
									{`Tốc độ`}
									<i className="text-danger">*</i>
								</Form.Label>
							</Form.Floating>
							{errors.speed && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errors.speed.message}
								</p>
							)}
						</Col>
					</Row>

					<Row className="mb-3">
						<Col xs="12" md="6">
							<Form.Floating>
								<Form.Control
									type="text"
									placeholder={`Đối tượng áp dụng`}
									name={`applyFor`}
									{...register(`applyFor`)}
								/>

								<Form.Label>
									{`Đối tượng áp dụng`}
									<i className="text-danger">*</i>
								</Form.Label>
							</Form.Floating>
							{errors.applyFor && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errors.applyFor.message}
								</p>
							)}
						</Col>
						<Col xs="12" md="6">
							<Form.Floating>
								<Form.Control
									type="text"
									placeholder={`Phù hợp với`}
									name={`suitable`}
									{...register(`suitable`)}
								/>

								<Form.Label>
									{`Phù hợp với`}
									<i className="text-danger">*</i>
								</Form.Label>
							</Form.Floating>
							{errors.suitable && (
								<p
									style={{
										fontSize: 13,
										color: "red",
										marginTop: 6,
									}}
								>
									{errors.suitable.message}
								</p>
							)}
						</Col>
					</Row>

					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder="Slug"
								name="slug"
								disabled
								value={slug}
							/>

							<Form.Label>
								Slug
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>

						{errMessage.slug && (
							<p
								style={{
									fontSize: 13,
									color: "red",
									marginTop: 6,
								}}
							>
								{errMessage.slug}
							</p>
						)}
					</div>

					{/* Content */}
					<div className="mb-3">
						<Form.Label>Nội dung</Form.Label>
						<TextEditor
							setContents={content}
							onChange={(value) => setContent(value)}
							placeholder={"Nội dung"}
						/>
					</div>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					type="submit"
					form="add-comboInternetTV-hook-form"
					variant="primary"
					disabled={isSendForm}
				>
					Lưu
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	name: yup
		.string()
		.required("Không được bỏ trống")
		.test("len", "Tiêu đề không quá 255 kí tự", (val) => val.length <= 255),
	price: yup.string().required("Không được bỏ trống"),
	period: yup.string().required("Không được bỏ trống"),
	speed: yup.string().required("Không được bỏ trống"),
});

export default ComboInternetTVAddModal;
