import { Col, Row } from "react-bootstrap";
import EnterpriseSection from "./EnterpriseSection";

const ListFeatures2 = ({ title, items }) => {
	return (
		<EnterpriseSection title={title}>
			<Row>
				<Col xs="12" md="6"></Col>
				<Col xs="12" md="6">
					{items.map((item, index) => {
						return (
							<Row
								key={item.id}
								className="enterprise-feature-hover d-flex mb-4"
							>
								<Col xs="2">
									<div
										style={{
											backgroundColor: "white",
											borderRadius: "50%",
											width: "3rem",
											height: "3rem",
										}}
										className="border display-flex-center"
									>
										<div className="enterprise-feature-circle mb-1">
											{index + 1}
										</div>
									</div>
								</Col>

								<Col xs="10">
									<div className="enterprise-feature-title text-overflow-3-line">
										{item.content}
									</div>
								</Col>
							</Row>
						);
					})}
				</Col>
			</Row>
		</EnterpriseSection>
	);
};

export default ListFeatures2;
