import { useEffect, useState } from "react";
import { Breadcrumb, Button, Container } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";

import TopicAddModal from "./TopicAddModal";
import TopicEditModal from "./TopicEditModal";
import Icon from "../../../../components/Icon";
import Loading from "../../../../components/Loading";
import DataTables from "../../../../components/DataTables";
import DateFormat from "../../../../components/DateFormat";

const Topic = () => {
	const [topics, setTopics] = useState([]);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedTopic, setSelectedTopic] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Chủ đề";
		getAllTopics();
	}, []);

	// API
	const getAllTopics = async () => {
		setIsLoading(true);
		await axios
			.get(`/topic/list`)
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setTopics(res.data.topics);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteTopic = async (topicIdId) => {
		await axios
			.delete(`/topic/delete/${topicIdId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getAllTopics();
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteTopic = (topicIdId) => {
		swal({
			title: `Bạn muốn xóa chủ đề này`,
			text: ` Lưu ý: Hành động không thể khôi phục lại`,
			icon: "warning",
			buttons: ["Đóng", "Xóa"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteTopic(topicIdId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllTopics();
		}
	};

	const handleShowAddModal = () => {
		setShowAddModal(true);
		setSelectedTopic();
	};

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllTopics();
		}
	};
	const handleShowEditModal = (topicId) => {
		setShowEditModal(true);
		setSelectedTopic(topicId);
	};

	const columns = [
		{
			name: "Tên chủ đề",
			selector: (row) => row.name,
			wrap: true,
		},
		{
			name: "Slug",
			selector: (row) => row.slug,
			wrap: true,
		},
		{
			name: "Cập nhật",
			selector: (row) => <DateFormat date={row.updatedAt} />,
		},
		{
			name: "Phương thức",
			selector: (row) => (
				<>
					<Button
						size="sm"
						variant="warning me-2"
						type="button"
						onClick={() => handleShowEditModal(row.id)}
					>
						<Icon icon="pencil" />
					</Button>

					<Button
						size="sm"
						variant="danger"
						type="button"
						onClick={() => handleDeleteTopic(row.id)}
					>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			<Breadcrumb className="mt-2">
				<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
				<Breadcrumb.Item active>Tin tức sự kiện</Breadcrumb.Item>
				<Breadcrumb.Item active>Chủ đề</Breadcrumb.Item>
			</Breadcrumb>

			<div className="d-block d-md-flex justify-content-between  mb-3">
				<div className="mt-3 mt-md-0">
					<Button
						variant="outline-primary"
						className="mt-2 mt-md-0"
						onClick={() => handleShowAddModal()}
					>
						<Icon icon="plus" /> {"Thêm mới"}
					</Button>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={topics} columns={columns} />
			)}

			{showAddModal ? (
				<TopicAddModal
					handleCloseModal={handleCloseAddModal}
					showModal={showAddModal}
				/>
			) : (
				""
			)}

			{showEditModal ? (
				<TopicEditModal
					handleCloseModal={handleCloseEditModal}
					showModal={showEditModal}
					selectedTopic={selectedTopic}
				/>
			) : (
				""
			)}
		</Container>
	);
};

export default Topic;
