import { Button, Col, Row } from "react-bootstrap";
import Icon from "../Icon";
import MoneyFormat from "../MoneyFormat";
import EnterpriseSection from "./EnterpriseSection";
import Carousel from "react-multi-carousel";

import { responsive3Items } from "../../utils/carouselResponsiveItem";

const listItems = [
	{
		id: 1,
		title: "Phí hòa mạng: ",
	},
	{
		id: 2,
		title: "Phí lắp đặt: ",
	},
	{
		id: 3,
		title: "Thời gian sử dụng: ",
	},
	{
		id: 4,
		title: "Thuế VAT: ",
	},
	{
		id: 5,
		title: "Thiết bị: ",
	},
	{
		id: 6,
		title: "Loại đăng ký: ",
	},
];

const ListSell = ({ title, items }) => {
	return (
		<EnterpriseSection title={title}>
			<Carousel responsive={responsive3Items} autoPlay infinite={true}>
				{items.map((item) => {
					return (
						<div
							key={item.id}
							className={`h-100 border p-3 p-md-5 text-center mx-3`}
						>
							<div
								style={{ color: "#555555" }}
								className="item-title text-uppercase text-overflow-2-line"
							>
								{item.name}
							</div>

							<MoneyFormat
								money={item.price}
								style={{
									fontSize: "3rem",
									color: "var(--primary-color)",
								}}
							/>

							<div className="my-3 text-overflow-2-line">
								{item.description}
							</div>

							<div className="text-start mt-4">
								{listItems.map((subItem) => {
									return (
										<Row key={subItem.id} className="mb-2">
											<Col xs="1" className="me-2">
												<Icon
													icon="check"
													style={{
														color: "#33d543",
														fontSize: "1.2rem",
													}}
												/>
											</Col>
											<Col
												xs="9"
												md="10"
												style={{
													color: "#6c6c6c",
													fontSize: 14,
												}}
												className="text-overflow-2-line"
											>
												{subItem.title}
											</Col>
										</Row>
									);
								})}
							</div>

							<Button className="primary-button w-100 mt-3">
								Đăng ký ngay
							</Button>
						</div>
					);
				})}
			</Carousel>
		</EnterpriseSection>
	);
};

export default ListSell;
