import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MOBILE } from "../../routes/routerUrl";
import MoneyFormat from "../MoneyFormat";

const EnterpriseItem = ({ item, className }) => {
	return (
		<div className={`radius-8 enterprise-card h-100 p-3 ${className}`}>
			<div
				style={{
					aspectRatio: "2/1",
					backgroundImage: `url(https://viettel.vn/images/sme/sme-home-v2/package-background.png)`,
					backgroundSize: "cover",
				}}
				className="image-default top-radius-8 text-uppercase"
			>
				<div className="item-title">{item.name}</div>
				<div className="text-overflow-2-line mb-4">
					{item.description}
				</div>

				<div>Giá gới + Phí khởi tạo</div>
				<MoneyFormat money={item.price} className="item-title fs-2" />
			</div>

			<div className="text-center">
				<Link to={`${MOBILE}/goi-cuoc/${item.slug}`}>
					<Button className="primary-button w-100 mt-3">
						Xem chi tiết
					</Button>
				</Link>

				<div className="d-flex justify-content-between mt-3">
					<Button className="mute-button w-50">Nhận tư vấn</Button>
					<div className="mx-2" />
					<Button className="mute-button w-50">Chi tiết</Button>
				</div>
			</div>
		</div>
	);
};

export default EnterpriseItem;
