import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import swal from "sweetalert";

import { Breadcrumb, Button, Container, Form } from "react-bootstrap";
import Icon from "../../../../components/Icon";
import DataTables from "../../../../components/DataTables";
import MoneyFormat from "../../../../components/MoneyFormat";
import Loading from "../../../../components/Loading";
import DateFormat from "../../../../components/DateFormat";

import MainPackageAddModal from "./MainPackageAddModal";
import MainPackageEditModal from "./MainPackageEditModal";

const MainPackage = () => {
	const [mainPackages, setMainPackages] = useState([]);
	const [keyword, setKeyword] = useState("");

	const [selectedMainPackage, setSelectedMainPackage] = useState(0);
	const [showAddModal, setShowAddModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Gói cước chính";
		getAllMainPackages("");
	}, []);

	// API
	const getAllMainPackages = async (keyword) => {
		setIsLoading(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 3,
					keyword: keyword,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setMainPackages(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteMainPackage = async (mainPackagesIdId) => {
		await axios
			.delete(`/service/delete/${mainPackagesIdId}`)
			.then((res) => {
				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});

					getAllMainPackages("");
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	const handleDeleteMainPackage = (mainPackageId) => {
		swal({
			title: `Bạn muốn xóa gói cước chính này`,
			text: ` Lưu ý: Hành động không thể khôi phục lại`,
			icon: "warning",
			buttons: ["Đóng", "Xóa"],
			successMode: true,
		}).then((ok) => {
			if (ok) {
				deleteMainPackage(mainPackageId);
			}
		});
	};

	const handleCloseAddModal = (isReload) => {
		setShowAddModal(false);
		if (isReload) {
			getAllMainPackages("");
		}
	};
	const handleShowAddModal = () => {
		setShowAddModal(true);
		setSelectedMainPackage();
	};

	const handleCloseEditModal = (isReload) => {
		setShowEditModal(false);
		if (isReload) {
			getAllMainPackages("");
		}
	};
	const handleShowEditModal = (mainPackageId) => {
		setShowEditModal(true);
		setSelectedMainPackage(mainPackageId);
	};

	const columns = [
		{
			name: "Tên gói cước",
			selector: (row) => row.name,
			wrap: true,
		},
		{
			name: "Giá gói",
			selector: (row) => <MoneyFormat money={row.price} />,
			wrap: true,
		},
		{
			name: "Thời gian",
			selector: (row) => row.period,
			wrap: true,
		},
		{
			name: "Loại gói",
			selector: (row) => row.serviceType.name,
			wrap: true,
		},
		{
			name: "Cập nhật",
			selector: (row) => <DateFormat date={row.updatedAt} />,
		},
		{
			name: "Phương thức",
			selector: (row) => (
				<>
					<Button
						size="sm"
						variant="warning me-2"
						type="button"
						onClick={() => handleShowEditModal(row.id)}
					>
						<Icon icon="pencil" />
					</Button>

					<Button
						size="sm"
						variant="danger"
						type="button"
						onClick={() => handleDeleteMainPackage(row.id)}
					>
						<Icon icon="trash" />
					</Button>
				</>
			),
		},
	];

	return (
		<Container fluid>
			<Breadcrumb className="mt-2">
				<Breadcrumb.Item active>Tổng quan</Breadcrumb.Item>
				<Breadcrumb.Item active>Di động</Breadcrumb.Item>
				<Breadcrumb.Item active>Gói cước chính</Breadcrumb.Item>
			</Breadcrumb>

			<div className="d-block d-md-flex justify-content-between mb-3">
				<div>
					<div className="d-flex">
						<Form.Control
							type="search"
							placeholder={"Nhập tên gói cước"}
							className="me-2"
							aria-label="Search"
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<Button
							variant="outline-secondary"
							onClick={() => getAllMainPackages(keyword)}
						>
							<Icon icon="magnifying-glass" />
						</Button>
					</div>
				</div>
				<div className="mt-3 mt-md-0">
					<Button
						variant="outline-primary"
						className="mt-2 mt-md-0"
						onClick={() => handleShowAddModal()}
					>
						<Icon icon="plus" /> {"Thêm mới"}
					</Button>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : (
				<DataTables data={mainPackages} columns={columns} />
			)}

			{showAddModal ? (
				<MainPackageAddModal
					handleCloseModal={handleCloseAddModal}
					showModal={showAddModal}
				/>
			) : (
				""
			)}

			{showEditModal ? (
				<MainPackageEditModal
					handleCloseModal={handleCloseEditModal}
					showModal={showEditModal}
					itemId={selectedMainPackage}
				/>
			) : (
				""
			)}
		</Container>
	);
};

export default MainPackage;
