import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { INTERNET_TV } from "../../../routes/routerUrl";

import MoneyFormat from "../../MoneyFormat";

import InternetBG2 from "../../../assets/banner/internet-bg-2.svg";
import IconHomeIT from "../../../assets/icon/icon-it.svg";

const InternetItem = ({ item, className }) => {
	return (
		<div
			style={{ backgroundColor: "var(--white)" }}
			className={`radius-8 hover-border h-100 ${className}`}
		>
			<div
				style={{
					aspectRatio: "263/85",
					backgroundImage: `url(${InternetBG2})`,
					color: "var(--white)",
					fontSize: "2rem",
				}}
				className="image-default top-radius-8 item-title text-uppercase display-flex-center"
			>
				{item.name}
			</div>

			<div
				style={{
					aspectRatio: "8/1",
					backgroundImage: `url(${IconHomeIT})`,
				}}
				className="image-default"
			/>

			<div className="px-3">
				<div className="item-title text-overflow-1-line">
					Tốc độ: {item.speed}
				</div>

				<div className="item-title text-overflow-1-line">
					<MoneyFormat
						money={item.price}
						style={{ fontSize: "2rem" }}
					/>
					<div
						style={{ fontSize: "1rem" }}
						className="d-inline fst-italic"
					>
						/{item.period}
					</div>
				</div>

				<div
					style={{
						overflowY: "scroll",
						minHeight: "5.9rem",
						maxHeight: "5.9rem",
					}}
					className="mt-3"
				>
					<Row className="mx-0 mb-3">
						<Col xs="1" className="me-2">
							<div
								style={{
									height: "1.5rem",
									width: "1.5rem",
									backgroundImage: `url(https://vietteltelecom.vn/images_content/ic-it-3.svg)`,
								}}
								className="image-default"
							/>
						</Col>
						<Col xs="9" md="10" className="item-text">
							{item.description}
						</Col>
					</Row>
				</div>

				<div className="text-center">
					<Button className="primary-button w-100 mt-3">
						Đăng ký
					</Button>

					<Link to={`${INTERNET_TV}/goi-cuoc/${item.slug}`}>
						<Button className="mute-button w-100 my-3">
							Xem chi tiết
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default InternetItem;
