import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import Input from "../../../components/Input";
import React from "react";
import { useForm } from "react-hook-form";
import yup from "../../../utils/yupGlobal";
import { yupResolver } from "@hookform/resolvers/yup";
import { Label } from "@radix-ui/react-dropdown-menu";
import Select from "../../../components/Select";
import MoneyFormat from "../../../components/MoneyFormat";

const orderFormSections = [
	{
		id: 1,
		title: "Thông tin khách hàng",
		formItems: [
			{
				id: 1,
				label: "Họ và tên *",
				placeholder: "Họ và tên",
				name: "fullname",
				type: "text",
			},
			{
				id: 2,
				label: "Số điện thoại *",
				placeholder: "Số điện thoại",
				name: "phonenumber",
				type: "text",
			},
			{
				id: 3,
				label: "Email",
				placeholder: "Email",
				name: "email",
				type: "text",
			},
			{
				id: 4,
				label: "Tên công ty",
				placeholder: "Tên công ty",
				name: "companyName",
				type: "text",
			},
			{
				id: 5,
				label: "Loại giấy tờ *",
				placeholder: "Loại giấy tờ",
				name: "typePaper",
				type: "select",
			},
			{
				id: 6,
				label: "Số giấy tờ *",
				placeholder: "Số giấy tờ",
				name: "numberPaper",
				type: "text",
			},
		],
	},
	{
		id: 2,
		title: "Địa chỉ",
		formItems: [
			{
				id: 1,
				label: "Tỉnh / Thành phố *",
				placeholder: "Tỉnh / Thành phố",
				name: "province",
				type: "select",
			},
			{
				id: 2,
				label: "Quận/ Huyện *",
				placeholder: "Quận/ Huyện",
				name: "district",
				type: "select",
			},
			{
				id: 3,
				label: "Phường/ Xã *",
				placeholder: "Phường/ Xã",
				name: "ward",
				type: "select",
			},
			{
				id: 4,
				label: "Địa chỉ chi tiết (số nhà/ đường/ phố) *",
				placeholder: "",
				name: "address",
				type: "text",
			},
			{
				id: 5,
				label: "Ghi chú",
				placeholder: "Ghi chú",
				name: "note",
				type: "text",
			},
		],
	},
	{
		id: 3,
		title: "Hình thức thanh toán",
		formItems: [],
	},
];

const EnterpriseOrder = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});
	return (
		<Container className="container-wrapper">
			<div
				style={{ fontSize: "1.5rem", fontWeight: 500 }}
				className="text-center py-5"
			>
				THÔNG TIN ĐƠN HÀNG
			</div>

			<Row className="mb-5">
				<Col md="12" xl="8">
					<Card
						style={{ boxShadow: "2px 2px 10px 0px #00000026" }}
						className="p-4 border-0"
					>
						<Form onSubmit={handleSubmit((data) => {})}>
							{orderFormSections.map((sectionItem) => {
								return (
									<Row key={sectionItem.id}>
										<div
											style={{
												fontSize: "1.5rem",
												fontWeight: 500,
											}}
											className="my-4"
										>
											{sectionItem.title}
										</div>
										{sectionItem.formItems.map((item) => {
											if (item.type === "select") {
												return (
													<Col
														xs="12"
														sm="6"
														key={item.id}
													>
														<Label
															style={{
																fontWeight: 500,
															}}
															className="mb-2"
														>
															{item.label}
														</Label>

														<Select
															placeholder={
																item.placeholder
															}
															className="mb-3"
														/>
													</Col>
												);
											}

											return (
												<Col
													xs="12"
													sm="6"
													key={item.id}
												>
													<Label
														style={{
															fontWeight: 500,
														}}
														className="mb-2"
													>
														{item.label}
													</Label>

													<Input
														placeholder={
															item.placeholder
														}
														name={item.name}
														register={register(
															item.name,
														)}
														errors={
															errors[item.name]
														}
														className="mb-3"
													/>
												</Col>
											);
										})}
									</Row>
								);
							})}

							<Col
								xs="12"
								xl="6"
								style={{
									border: "1px solid var(--primary-color)",
									backgroundColor: "#F8F8F8",
									borderRadius: 4,
								}}
								className="d-flex p-5"
							>
								<div
									style={{
										border: "1px solid var(--primary-color)",
										height: "1.5rem",
										width: "1.5rem",
										borderRadius: "50%",
									}}
									className="display-flex-center me-2"
								>
									<div
										style={{
											backgroundColor:
												"var(--primary-color)",
											height: "1rem",
											width: "1rem",
											borderRadius: "50%",
										}}
									/>
								</div>
								<div>Thanh toán khi nhận hàng</div>
							</Col>
						</Form>
					</Card>
				</Col>
				<Col md="12" xl="4">
					<Card
						style={{ boxShadow: "2px 2px 10px 0px #00000026" }}
						className="p-4 border-0"
					>
						<div
							style={{
								fontSize: "1.5rem",
								fontWeight: 500,
							}}
							className="text-center my-4"
						>
							Thông tin đơn hàng
						</div>

						<div className="d-flex justify-content-between pb-3">
							<div className="fw-light">Loại gói</div>
							<div style={{ fontWeight: 500 }}>Gói CA1_GH</div>
						</div>
						<div className="d-flex justify-content-between border-bottom pb-3">
							<div className="fw-light">Đơn giá</div>
							<div style={{ fontWeight: 500 }}>
								<MoneyFormat money={1276000} />
							</div>
						</div>

						<div className="d-flex justify-content-between align-items-center py-3">
							<div className="fw-light">Thành tiền</div>
							<div style={{ fontWeight: 500 }}>
								<MoneyFormat
									money={1276000}
									style={{ fontSize: "1.5rem" }}
								/>
							</div>
						</div>
					</Card>

					<Button
						style={{
							backgroundColor: "var(--primary-color)",
							color: "white",
							borderRadius: 8,
							border: "1px solid var(--primary-color)",
						}}
						className="w-100 my-3 py-2"
					>
						Xác nhận đặt hàng
					</Button>
					<Button
						style={{
							backgroundColor: "white",
							color: "var(--primary-color)",
							borderRadius: 8,
							border: "1px solid var(--primary-color)",
						}}
						className="w-100 py-2"
					>
						Quay lại
					</Button>
				</Col>
			</Row>
		</Container>
	);
};

const Schema = yup.object().shape({
	password: yup
		.string()
		.required("VI_FIELD_REQUIRE")
		.test(
			"len",
			"VI_PASSWORD_LENGTH",
			(val) => val.length >= 6 && val.length <= 32,
		),
	username: yup
		.string()
		.required("VI_FIELD_REQUIRE")
		.test(
			"len",
			"VI_USER_LENGTH",
			(val) => val.length >= 6 && val.length <= 32,
		),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "VI_PASSWORD_MATCH"),
	fullname: yup.string().required("VI_FIELD_REQUIRE"),
	phonenumber: yup
		.string()
		.required("VI_FIELD_REQUIRE")
		.phone("aaaaaaaaaaaaaa"),
	email: yup.string().email("VI_EMAIL_FORMAT").required("VI_FIELD_REQUIRE"),
	address: yup.string().required("VI_FIELD_REQUIRE"),
});

export default EnterpriseOrder;
