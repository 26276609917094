import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { INTERNET_TV } from "../../../routes/routerUrl";
import MoneyFormat from "../../MoneyFormat";

import IconComputer from "../../../assets/icon/icon-computer.svg";
import IconHomeUser from "../../../assets/icon/icon-home-user.svg";
import IconHome from "../../../assets/icon/icon-home.svg";
import IconHomeIT from "../../../assets/icon/icon-it.svg";

const listItems = [
	{
		id: 1,
		icon: IconHomeIT,
	},
	{
		id: 2,
		icon: IconHomeUser,
	},
	{
		id: 3,
		icon: IconHome,
	},
	{
		id: 4,
		icon: IconComputer,
	},
];

const listIcons = [
	{
		id: 1,
		icon: "http://media.vietteltelecom.vn/upload/ckfinder/files/image%205.png",
	},
	{
		id: 2,
		icon: "http://media.vietteltelecom.vn/upload/ckfinder/files/IconInternet.png",
	},
	{
		id: 3,
		icon: "http://media.vietteltelecom.vn/upload/ckfinder/files/Group%209397.png",
	},
	{
		id: 4,
		icon: "http://media.vietteltelecom.vn/upload/ckfinder/files/image%209.png",
	},
	{
		id: 5,
		icon: "http://media.vietteltelecom.vn/upload/ckfinder/files/image%2010.png",
	},
	{
		id: 6,
		icon: "http://media.vietteltelecom.vn/upload/ckfinder/files/image%2011.png",
	},
	{
		id: 7,
		icon: "http://media.vietteltelecom.vn/upload/ckfinder/files/image%2013.png",
	},
];

const ComboItem2 = ({ item, className, style }) => {
	return (
		<div style={style} className={`h-100 border p-3 ${className}`}>
			<div className="item-title">{item.name}</div>

			<div className="combo-overflow">
				{listItems.map((subItem) => {
					let content = "";

					if (subItem.id === 1) {
						content = `${item.speed}`;
					}

					if (subItem.id === 2) {
						content = "Thiết bị kết nối: Laptop, TV, Phone";
					}

					if (subItem.id === 3) {
						content = item.applyFor;
					}

					if (subItem.id === 4) {
						content = item.suitable;
					}

					return (
						<Row key={subItem.id} className="mb-3">
							<Col xs="1" className="me-2">
								<div
									style={{
										height: "1.5rem",
										width: "1.5rem",
										backgroundImage: `url(${subItem.icon})`,
									}}
									className="image-default"
								/>
							</Col>
							<Col
								xs="9"
								md="10"
								className="item-text text-overflow-2-line"
							>
								{content}
							</Col>
						</Row>
					);
				})}
			</div>

			<div className="d-flex justify-content-between border-top my-2 py-4">
				{listIcons.map((icon) => {
					return (
						<div
							key={icon.id}
							style={{
								height: "2rem",
								width: "2rem",
								backgroundImage: `url(${icon.icon})`,
							}}
							className="image-default"
						/>
					);
				})}
			</div>

			<div>Giá một tháng</div>
			<div className="text-overflow-1-line">
				<MoneyFormat
					money={item.price}
					style={{ fontSize: "2rem", fontWeight: "bold" }}
				/>
				<div className="d-inline fw-bold">/tháng</div>
			</div>

			<Button className="primary-button w-100 mt-3">Đăng ký</Button>

			<Link to={`${INTERNET_TV}/goi-cuoc/${item.slug}`}>
				<Button className="mute-button w-100 mt-3">
					Chi tiết gói cước
				</Button>
			</Link>
		</div>
	);
};

export default ComboItem2;
