import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { INTERNET_TV } from "../../../routes/routerUrl";
import MoneyFormat from "../../MoneyFormat";

const TVItem = ({ item, className }) => {
	return (
		<div
			style={{
				backgroundColor: "#44494d",
				color: "white",
				borderRadius: 8,
			}}
			className={`h-100 ${className}`}
		>
			<Link to={`${INTERNET_TV}/goi-cuoc/${item.slug}`}>
				<div
					style={{
						aspectRatio: "1233/929",
						backgroundImage: `url(https://media.vietteltelecom.vn/upload/vtManageFtth/ea/05/5e/600b8c02c4d7e55615fd95e4a083c13bf7264dda.jpg)`,
						backgroundSize: "cover",
						borderTopRightRadius: 8,
						borderTopLeftRadius: 8,
					}}
					className="image-default"
				/>
			</Link>
			<div className="p-3">
				<Row>
					<Col
						xs="12"
						md="6"
						style={{ color: "white" }}
						className="item-title text-overflow-1-line"
					>
						<MoneyFormat
							money={item.price}
							style={{ fontSize: "2rem" }}
						/>
						/tháng
					</Col>

					<Col
						xs="12"
						md="6"
						style={{
							fontSize: "0.8rem",
							fontStyle: "italic",
							color: "white",
						}}
						className="text-start text-md-end"
					>
						Miễn phí cước data 4G Viettel khi xem trên di động
					</Col>
				</Row>

				<Row className="mt-3">
					<Col xs="12" md="5">
						<Row>
							<Col xs="1" className="me-2">
								<div
									style={{
										height: "1.5rem",
										width: "1.5rem",
										backgroundImage: `url(https://vietteltelecom.vn/images_content/ic-tv-pack-1.svg)`,
									}}
									className="image-default"
								/>
							</Col>
							<Col
								xs="9"
								md="10"
								style={{ color: "white" }}
								className="item-text text-overflow-2-line"
							>
								{item.period}
							</Col>
						</Row>
					</Col>
					<Col xs="12" md="7">
						<Row>
							<Col xs="1" className="me-2">
								<div
									style={{
										height: "1.5rem",
										width: "1.5rem",
										backgroundImage: `url(https://vietteltelecom.vn/images_content/ic-tv-pack-2.svg)`,
									}}
									className="image-default"
								/>
							</Col>
							<Col
								xs="9"
								md="10"
								style={{ color: "white" }}
								className="item-text text-overflow-2-line"
							>
								{item.description}
							</Col>
						</Row>
					</Col>
				</Row>

				<Link to={`${INTERNET_TV}/goi-cuoc/${item.slug}`}>
					<Button className="primary-button w-100 mt-3">
						Đăng ký
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default TVItem;
