import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	Form,
	Container,
	Navbar,
	Row,
	Col,
	NavDropdown,
} from "react-bootstrap";
import Icon from "../../components/Icon";
import Logo from "../../assets/image/Logo.png";
import { INTRODUCTION, NEW_EVENT } from "../../routes/routerUrl";

import navbarItems from "../../utils/navbarItems";

function UserNavBar({ setShowSideBar, showSideBar }) {
	const [showDropdown, setShowDropdown] = useState(0);

	const navigate = useNavigate();

	return (
		<>
			<div
				style={{ position: "sticky", top: 0, zIndex: 1052 }}
				className="header py-2"
			>
				<Container>
					<Row className="mx-0">
						<Col xs="12" md="2">
							<Link to="/">
								<img src={Logo} alt="Viettel" />
							</Link>
						</Col>
						<Col xs="8">
							<div className="d-flex align-items-center">
								<Link
									to={INTRODUCTION}
									className="header-text me-3"
								>
									<Icon icon="globe" /> Giới thiệu viettel
								</Link>
								<Link
									to={`${NEW_EVENT}`}
									className="header-text me-3"
								>
									<Icon icon="newspaper" /> Tin tức sự kiện
								</Link>
							</div>
						</Col>
						<Col xs="2">
							<Form.Control placeholder="Tìm kiếm" />
						</Col>
					</Row>
				</Container>
			</div>

			<Navbar expand="lg" className="nav-height px-0">
				<Container className="desktop-nav justify-content-center align-items-center w-100">
					{navbarItems.map((navbarItem) => {
						return (
							<NavDropdown
								key={navbarItem.id}
								title={
									<div
										style={{ fontSize: "1.1rem" }}
										className="nav-text"
										onClick={() =>
											navigate(navbarItem.link)
										}
									>
										{navbarItem.title}
									</div>
								}
								className="pe-4"
								show={showDropdown === navbarItem.id}
								onMouseEnter={() =>
									setShowDropdown(navbarItem.id)
								}
								onMouseLeave={() => setShowDropdown(0)}
							>
								{navbarItem.subTitle.map((subItem) => {
									return (
										<NavDropdown.Item
											as={Link}
											key={subItem.id}
											to={subItem.link}
										>
											{subItem.title}
										</NavDropdown.Item>
									);
								})}
							</NavDropdown>
						);
					})}
				</Container>

				<Container className="mobile-nav justify-content-between align-items-center w-100">
					<div
						onClick={() => {
							setShowSideBar(!showSideBar);
						}}
						className="toggle-button"
					>
						<Icon icon="bars" />
					</div>

					<Link to="/">
						<img src={Logo} alt="Viettel" />
					</Link>

					<div></div>
				</Container>
			</Navbar>
		</>
	);
}

export default UserNavBar;
