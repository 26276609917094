import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MOBILE } from "../../../routes/routerUrl";
import MoneyFormat from "../../MoneyFormat";

const MainPackageItem = ({ item, className }) => {
	return (
		<div
			className={`viettel-radius hover-border main-package-card h-100 ${className}`}
		>
			<div
				style={{
					aspectRatio: "6/1",
					backgroundImage: `url(https://vietteltelecom.vn/images_content/img-fee-pack.png)`,
				}}
				className="image-default top-radius-8 text-uppercase fw-bold display-flex-center mt-2"
			>
				<div
					style={{
						color: "var(--black)",
					}}
					className="item-title"
				>
					{item.name}
				</div>
			</div>

			<div
				style={{
					color: "var(--primary-color)",
				}}
				className="text-center mt-2"
			>
				<MoneyFormat money={item.price} className="item-title" />
				<div className="d-inline item-text">/{item.period}</div>
			</div>

			<div className="p-3">
				<div className="main-package-height item-text text-overflow-4-line">
					{item.description}
				</div>

				<div className="text-center">
					<Link to={`${MOBILE}/goi-cuoc/${item.slug}`}>
						<Button className="primary-button w-100 mt-3">
							Xem chi tiết
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default MainPackageItem;
