import Carousel from "react-multi-carousel";

import Section from "../../Section";
import Loading from "../../Loading";
import MainPackageItem from "./MainPackageItem";
import NoData from "../../NoData";

import { responsive4Items } from "../../../utils/carouselResponsiveItem";

const ListMainPackage = ({
	title,
	allLink,
	itemLink,
	isLoading,
	listItems,
}) => {
	return (
		<Section title={title} allLink={allLink}>
			{isLoading ? (
				<Loading />
			) : listItems?.length > 0 ? (
				<Carousel
					responsive={responsive4Items}
					autoPlay
					infinite={true}
				>
					{listItems?.map((item) => {
						return (
							<MainPackageItem
								key={item.id}
								item={item}
								link={itemLink}
								className="mx-2"
							/>
						);
					})}
				</Carousel>
			) : (
				<NoData />
			)}
		</Section>
	);
};

export default ListMainPackage;
