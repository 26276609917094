import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import { Col, Container, Row } from "react-bootstrap";
import MyBreadcrumb from "../../../components/MyBreadcrumb";
import Icon from "../../../components/Icon";
import BottomBannerInternetTV from "../../../components/InternetTV/BottomBannerInternetTV";
import ListInternet from "../../../components/InternetTV/Internet/ListInternet";

import "./InternetTVStyle.css";

import { INTERNET_TV } from "../../../routes/routerUrl";
import ListCombo from "../../../components/InternetTV/Combo/ListCombo";

const internetTVItems = [
	{
		id: 1,
		title: "Internet",
		description:
			"Với hệ thống gói cước đa dạng, Internet đáp ứng nhu cầu sử dụng của người dùng.",
		banner: "http://media.vietteltelecom.vn/upload/ckfinder/files/icon%20Internet.png",
	},
	{
		id: 2,
		title: "Truyền hình",
		description: "Mang cả thế giới giải trí đến ngôi nhà của bạn.",
		banner: "http://media.vietteltelecom.vn/upload/ckfinder/files/Icon%20truyền%20hình.png",
	},
	{
		id: 3,
		title: "Combo internet Truyền hình",
		description:
			"(Truyền hình, Home wifi, Camera AI,...) mang đến trải nghiệm tốt nhất tới khách hàng",
		banner: "http://media.vietteltelecom.vn/upload/ckfinder/files/icon%20combo.png",
	},
];

const subContents = [
	{ id: 1, description: "Giá cước ưu đãi, hấp dẫn nhất thị trường" },
	{ id: 2, description: "Tốc độ truy cập internet cao và ổn định" },
	{ id: 3, description: "Tích hợp nhiều dịch vụ trên 01 đường dây" },
	{ id: 4, description: "Lắp đặt nhanh chóng, hỗ trợ 24/7" },
];

const InternetTV = () => {
	const [internets, setInternets] = useState([]);
	const [combos, setCombos] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title =
			"Đăng ký Internet truyền hình Viettel cho ngôi nhà của bạn";

		getAllInternets();
		getAllCombos();
	}, []);

	// API
	const getAllInternets = async () => {
		setIsLoading(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 4,
					limit: 20,
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setInternets(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllCombos = async () => {
		setIsLoading(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: 6,
					keyword: "",
				},
			})
			.then((res) => {
				setIsLoading(false);
				if (res.data.errCode === 0) {
					setCombos(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// API

	return (
		<>
			<div
				style={{
					aspectRatio: "16/5",
					backgroundImage: `url(http://media.vietteltelecom.vn/upload/InternetInternetPackageBuyData/fe/bb/bf/ba225ca1933892bb315fc7bcd499dfc366cf6d25.jpg)`,
				}}
				className="image-default"
			/>

			<Container className="container-wrapper">
				<MyBreadcrumb list={["Internet/Truyền hình"]} />

				<Row>
					{internetTVItems.map((item) => {
						return (
							<Col key={item.id} className="text-center">
								<div
									key={item.id}
									style={{
										aspectRatio: "438/163",
										backgroundImage: `url(${item.banner})`,
									}}
									className="image-default"
								/>

								<div className="text-overflow-1-line fw-bold my-2">
									{item.title}
								</div>

								<div className="text-overflow-2-line">
									{item.description}
								</div>
							</Col>
						);
					})}
				</Row>
			</Container>

			<div className="my-5">
				<div className="inter-title fw-bold text-center mb-4">
					ĐĂNG KÝ INTERNET CHO NGÔI NHÀ CỦA BẠN
				</div>

				<div
					style={{
						backgroundImage: `url(http://media.vietteltelecom.vn/upload/ckfinder/images/6%207%20copy.jpg)`,
					}}
					className="internet-image-bg"
				>
					<Container className="container-wrapper d-flex align-items-end align-items-md-center h-100 py-5">
						<div>
							{subContents.map((item) => {
								return (
									<div
										key={item.id}
										style={{ color: "white" }}
										className="item-text d-flex align-items-center mb-2 mb-md-3 mb-xl-5"
									>
										<div className="inter-circle display-flex-center me-3">
											<Icon icon="check" />
										</div>
										<div
											style={{
												maxWidth: "16rem",
												minWidth: "16rem",
											}}
										>
											{item.description}
										</div>
									</div>
								);
							})}
						</div>
					</Container>
				</div>
			</div>

			<Container className="container-wrapper">
				<ListInternet
					title="DANH SÁCH GÓI INTERNET"
					listItems={internets}
					colShow={true}
					isLoading={isLoading}
					allLink={`${INTERNET_TV}/internet`}
				/>
			</Container>

			<Container className="container-wrapper">
				<div className="inter-title fw-bold text-center mb-4">
					DANH SÁCH GÓI COMBO INTERNET - TRUYỀN HÌNH
				</div>

				<ListCombo listItems={combos} />
			</Container>

			<BottomBannerInternetTV />
		</>
	);
};

export default InternetTV;
