import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";

import MyBreadcrumb from "../../../components/MyBreadcrumb";
import Section from "../../../components/Section";
import DateFormat from "../../../components/DateFormat";
import RelatedNew from "../../../components/EventNew/RelatedNew";
import ClientLoading from "../../../components/ClientLoading";
import NoData from "../../../components/NoData";

import { responsive3Items } from "../../../utils/carouselResponsiveItem";

const NewEventDetail = () => {
	let { slug } = useParams();

	const [newDetail, setNewDetail] = useState({});
	const [relatedNews, setRelatedNews] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getDetailNewEvent(slug);
	}, [slug]);

	const getDetailNewEvent = (newEventSlug) => {
		setIsLoading(true);
		axios
			.get(`/client/newEvent/${newEventSlug}`)
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setNewDetail(res.data.newEvent);
					setRelatedNews(res.data.relatedNewEvents);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			<Container className="container-wrapper">
				<MyBreadcrumb list={["Tin tức", "Tin tức sự kiện"]} />

				{isLoading ? <ClientLoading /> : ""}

				<div className="mb-5">
					<div className="item-title">{newDetail.title}</div>
					<DateFormat className={`mt-2`} />

					<div
						dangerouslySetInnerHTML={{
							__html: newDetail.content,
						}}
					/>
				</div>

				<Section title="TIN TỨC KHÁC">
					{relatedNews && relatedNews.length > 0 ? (
						<Carousel
							responsive={responsive3Items}
							autoPlay
							infinite={true}
						>
							{relatedNews.map((newItem) => {
								return (
									<RelatedNew
										key={newItem.id}
										item={newItem}
									/>
								);
							})}
						</Carousel>
					) : (
						<NoData />
					)}
				</Section>
			</Container>
		</>
	);
};

export default NewEventDetail;
