import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

import { Button, Col, Container, Row } from "react-bootstrap";
import MyBreadcrumb from "../../../../components/MyBreadcrumb";
import MoneyFormat from "../../../../components/MoneyFormat";
import ClientLoading from "../../../../components/ClientLoading";

import Gift from "../../../../assets/banner/bg-endow.png";
import RigthBg from "../../../../assets/banner/img-pack-detail-2.png";

import Calendar from "../../../../assets/icon/icon-calendar-sm.png";
import Service from "../../../../assets/icon/icon-service-sm.png";
import Wallet from "../../../../assets/icon/icon-wallet-sm.png";
import ListDataPackage from "../../../../components/Mobile/DataPackage/ListDataPackage";

const dataPackageItems = [
	{
		id: 1,
		label: "Tên gói cước",
		value: "",
		icon: Service,
	},
	{
		id: 2,
		label: "Giá gói cước",
		value: "",
		icon: Wallet,
	},
	{
		id: 3,
		label: "Thời gian",
		value: "",
		icon: Calendar,
	},
];

const InfoItem = ({ item, value }) => {
	return (
		<Col xs="4">
			<div
				style={{
					backgroundColor: "var(--white)",
					overflowX: "hidden",
				}}
				className="d-flex align-items-center border p-2 radius-8"
			>
				<div
					style={{
						width: "2.5rem",
						height: "2.5rem",
						backgroundImage: `url(${item.icon})`,
					}}
					className="image-default me-2"
				/>
				<div>
					<div>{item.label}</div>
					<div className="fw-bold item-title">
						{item.id === 2 ? <MoneyFormat money={value} /> : value}
					</div>
				</div>
			</div>
		</Col>
	);
};

const DataPackageDetail = () => {
	let { slug } = useParams();

	const [dataPackage, setDataPackage] = useState({});
	const [relatedDataPackage, setRelatedDataPackage] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Chi tiết gói";

		getDetailDataPackage(slug);
	}, [slug]);

	const getDetailDataPackage = (dataPackageSlug) => {
		setIsLoading(true);
		axios
			.get(`/client/service/${dataPackageSlug}`)
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setDataPackage(res.data.service);
					setRelatedDataPackage(res.data.relatedServices);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<Container className="container-wrapper">
			<MyBreadcrumb
				list={["Dịch vụ di động", "Gói cước Data", "Chi tiết gói"]}
			/>

			{isLoading ? <ClientLoading /> : ""}

			<Row>
				<Col xs="12" md="8">
					<Row>
						<InfoItem
							item={dataPackageItems[0]}
							value={dataPackage.name}
						/>
						<InfoItem
							item={dataPackageItems[1]}
							value={dataPackage.price}
						/>
						<InfoItem
							item={dataPackageItems[2]}
							value={dataPackage.period}
						/>
					</Row>

					<div className="data-package-detail-card border p-3 mt-4">
						<div
							style={{ backgroundColor: "var(--white)" }}
							className="radius-8 fw-bold px-3 py-2"
						>
							Ưu đãi
						</div>

						<div
							style={{
								backgroundColor: "var(--white)",
								paddingBottom: "5rem",
							}}
							className="radius-8 pt-2 pb-5"
						>
							<div
								style={{
									maxHeight: "10.5rem",
									minHeight: "10.5rem",
									overflowY: "scroll",
								}}
								className="px-3"
							>
								<div
									dangerouslySetInnerHTML={{
										__html: dataPackage.content,
									}}
								/>
							</div>
						</div>
					</div>

					<div
						style={{
							aspectRatio: "735/68",
							backgroundImage: `url(${Gift})`,
							marginTop: "-9%",
						}}
						className="image-default"
					/>
				</Col>
				<Col md="4" className="d-none d-md-block">
					<div
						style={{
							width: "100%",
							aspectRatio: "1.1/1",
							backgroundImage: `url(${RigthBg})`,
							backgroundRepeat: "no-repeat",
							backgroundSize: "contain",
							backgroundPosition: "center",
						}}
					/>
				</Col>
			</Row>

			<Button className="d-none d-md-block primary-button mt-5">
				Đăng ký
			</Button>

			<Button
				style={{
					position: "fixed",
					left: 0,
					bottom: 0,
					zIndex: 9999,
					width: "100%",
				}}
				className="d-md-none primary-button "
			>
				Đăng ký
			</Button>

			<ListDataPackage
				title="GÓI TƯƠNG TỰ"
				itemLink="goi-data"
				listItems={relatedDataPackage}
				isLoading={false}
			/>
		</Container>
	);
};

export default DataPackageDetail;
