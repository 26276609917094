import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MOBILE } from "../../../routes/routerUrl";

import MoneyFormat from "../../MoneyFormat";

import InternetBG1 from "../../../assets/banner/internet-bg-1.svg";
import IconDolar from "../../../assets/icon/icon-dola.svg";
import IconData from "../../../assets/icon/icon-data.svg";

const DataPackageItem = ({ item, link, className }) => {
	return (
		<div
			style={{ cursor: "pointer", background: "none" }}
			className={`normal-border-16 hover-border h-100 ${className}`}
		>
			<Link
				to={`${MOBILE}/${link}/${item.slug}`}
				style={{ textDecoration: "none" }}
			>
				<div
					style={{
						aspectRatio: "2.98/1",
						backgroundImage: `url(${InternetBG1})`,
					}}
					className="image-default top-radius-8 text-uppercase fw-bold display-flex-center"
				>
					<div
						style={{
							color: "var(--white)",
							fontSize: "1.5rem",
						}}
						className="item-title"
					>
						{item.name}
					</div>
				</div>

				<div
					style={{ color: "var(--black)" }}
					className="px-1 py-3 px-md-3 item-text"
				>
					<Row>
						<Col xs="1" className="me-2">
							<div
								style={{
									height: "1.5rem",
									width: "1.5rem",
									backgroundImage: `url(${IconDolar})`,
								}}
								className="image-default"
							/>
						</Col>
						<Col xs="9" md="10">
							<MoneyFormat money={item.price} />
						</Col>
					</Row>

					<Row>
						<Col xs="1" className="me-2">
							<div
								style={{
									height: "1.5rem",
									width: "1.5rem",
									backgroundImage: `url(${IconData})`,
								}}
								className="image-default"
							/>
						</Col>
						<Col
							xs="9"
							md="10"
							className="data-package-height text-overflow-2-line"
						>
							{item.description}
						</Col>
					</Row>

					<div className="text-center">
						<Button className="mute-button w-75 w-md-100 mx-md-0 mt-3">
							Đăng ký
						</Button>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default DataPackageItem;
