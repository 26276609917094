import { Button, Col, Row } from "react-bootstrap";
import MoneyFormat from "../MoneyFormat";
import EnterpriseSection from "./EnterpriseSection";
import Carousel from "react-multi-carousel";
import InternetBG2 from "../../assets/banner/internet-bg-2.svg";
import Icon from "../Icon";
import { responsive3Items } from "../../utils/carouselResponsiveItem";

const ListSell2 = ({ title, items, type }) => {
	return (
		<EnterpriseSection title={title}>
			<Carousel responsive={responsive3Items} autoPlay infinite={true}>
				{items.map((item) => {
					return (
						<div
							key={item.id}
							style={{ backgroundColor: "var(--white)" }}
							className={`radius-8 hover-border h-100 mx-3`}
						>
							<div
								style={{
									aspectRatio: "263/85",
									backgroundImage: `url(${InternetBG2})`,
									color: "var(--white)",
									fontSize: "2rem",
								}}
								className="image-default top-radius-8 item-title text-uppercase fw-bold display-flex-center"
							>
								{item.name}
							</div>

							<div className="p-3">
								{type === 1 ? (
									<>
										<div className="d-flex justify-content-between mb-3">
											<div>Số lượng HĐ</div>
											<div className="fw-bold">200</div>
										</div>
										<div className="d-flex justify-content-between mb-3">
											<div>Phí gói</div>
											<div className="fw-bold">
												<MoneyFormat money={10000000} />
											</div>
										</div>
										<div className="d-flex justify-content-between mb-3">
											<div>Phí chứng thực</div>
											<div className="fw-bold">
												<MoneyFormat money={10000000} />
											</div>
										</div>
										<div className="d-flex justify-content-between border-bottom pb-4">
											<div>Phí khởi tạo</div>
											<div className="fw-bold">
												<MoneyFormat money={10000000} />
											</div>
										</div>
									</>
								) : (
									<>
										<Row className="mb-3">
											<Col xs="1">
												<Icon
													icon="circle-check"
													style={{
														color: "var(--primary-color)",
													}}
												/>
											</Col>
											<Col
												xs="10"
												className="text-overflow-2-line"
											>
												Lượt ký:
											</Col>
										</Row>
										<Row className="mb-3">
											<Col xs="1">
												<Icon
													icon="circle-check"
													style={{
														color: "var(--primary-color)",
													}}
												/>
											</Col>
											<Col
												xs="10"
												className="text-overflow-2-line"
											>
												Đích ký:
											</Col>
										</Row>
										<Row className="border-bottom pb-4">
											<Col xs="1">
												<Icon
													icon="circle-check"
													style={{
														color: "var(--primary-color)",
													}}
												/>
											</Col>
											<Col
												xs="10"
												className="text-overflow-2-line"
											>
												Thời gian sử dụng:
											</Col>
										</Row>
									</>
								)}

								<div className="d-flex justify-content-between mt-4">
									<div>
										<div>Giá bán</div>
										<div
											style={{
												fontSize: "0.8rem",
												fontStyle: "italic",
											}}
										>
											Đã bao gồm VAT
										</div>
									</div>
									<div className="item-title">
										<MoneyFormat money={item.price} />
									</div>
								</div>
							</div>

							<div className="text-center p-3">
								<Button className="primary-button w-100">
									Đăng ký ngay
								</Button>

								<Button className="mute-button w-100 mt-3">
									Nhận tư vấn
								</Button>
							</div>

							{type === 1 ? (
								<div
									style={{ fontSize: "0.9rem" }}
									className="p-3 pt-0"
								>
									<Row className="mt-1">
										<Col xs="1">
											<div
												style={{
													height: 12,
													width: 12,
													backgroundImage: `url(https://vietteltelecom.vn/images/sme/smev3/tick.png)`,
												}}
												className="image-default mt-2"
											/>
										</Col>
										<Col
											xs="10"
											className="text-overflow-2-line"
										>
											Đăng ký kèm SMS Brandname với giá
											150đ/SMS nội mạng Viettel
										</Col>
									</Row>
									<Row className="mt-1">
										<Col xs="1">
											<div
												style={{
													height: 12,
													width: 12,
													backgroundImage: `url(https://vietteltelecom.vn/images/sme/smev3/tick.png)`,
												}}
												className="image-default mt-2"
											/>
										</Col>
										<Col
											xs="10"
											className="text-overflow-2-line"
										>
											Đăng ký kèm eKYC chỉ từ 1000đ/SMS
										</Col>
									</Row>
								</div>
							) : (
								""
							)}
						</div>
					);
				})}
			</Carousel>
		</EnterpriseSection>
	);
};

export default ListSell2;
