import { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";
import convertToSlug from "../../../../utils/slugFormatter";

const addTopicFormItems = [
	{
		id: 1,
		placeholder: "Tên chủ đề",
		name: "name",
	},
];

const TopicAddModal = ({ handleCloseModal, showModal }) => {
	const [slug, setSlug] = useState("");
	const [isSendForm, setIsSendForm] = useState(false);

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		resolver: yupResolver(Schema),
	});

	// Api
	const createNewTopic = async (data) => {
		setIsSendForm(true);

		await axios
			.post(`/topic/create`, {
				name: data.name,
				slug: slug,
			})
			.then((res) => {
				setIsSendForm(false);

				if (res.data.errCode === 0) {
					toast(res.data.errMessage, {
						type: "success",
						autoClose: 1000,
					});
					handleCloseModal(true);
				} else {
					toast("", {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Api

	return (
		<Modal
			show={showModal}
			onHide={() => handleCloseModal(false)}
			backdrop="static"
		>
			<Modal.Header closeButton={!isSendForm}>
				<Modal.Title>Thêm mới chủ đề</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Form
					id="add-topic-hook-form"
					onSubmit={handleSubmit((data) => {
						createNewTopic(data);
					})}
					className="mb-5"
				>
					{addTopicFormItems.map((formItem) => {
						return (
							<div key={formItem.id} className="mb-3">
								<Form.Floating>
									<Form.Control
										type="text"
										placeholder={formItem.placeholder}
										name={formItem.name}
										{...register(formItem.name, {
											onChange: () => {
												setSlug(
													convertToSlug(
														getValues("name"),
													),
												);
											},
										})}
									/>

									<Form.Label>
										{formItem.placeholder}
										<i className="text-danger">*</i>
									</Form.Label>
								</Form.Floating>

								{errors[formItem.name] && (
									<p
										style={{
											fontSize: 13,
											color: "red",
											marginTop: 6,
										}}
									>
										{errors[formItem.name].message}
									</p>
								)}
							</div>
						);
					})}

					<div className="mb-3">
						<Form.Floating>
							<Form.Control
								type="text"
								placeholder="Slug"
								name="slug"
								disabled
								value={slug}
							/>

							<Form.Label>
								Slug
								<i className="text-danger">*</i>
							</Form.Label>
						</Form.Floating>
					</div>
				</Form>
			</Modal.Body>

			<Modal.Footer>
				<Button
					type="button"
					variant="secondary"
					onClick={() => handleCloseModal(false)}
					disabled={isSendForm}
				>
					Đóng
				</Button>
				<Button
					type="submit"
					form="add-topic-hook-form"
					variant="primary"
					disabled={isSendForm}
				>
					Lưu
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

const Schema = yup.object().shape({
	name: yup.string().required("Không được bỏ trống"),
});

export default TopicAddModal;
