const carouselItem = [
	{
		id: 1,
		banner: "http://media.vietteltelecom.vn/upload/InternetInternetPackageBuyData/81/2b/b8/f0a5e690098ba4cbc99aa5f9595008f0c966f3ec.jpg",
	},
	{
		id: 2,
		banner: "http://media.vietteltelecom.vn/upload/InternetInternetPackageBuyData/71/36/67/9d1d565965938c0f7805c75e19aba2447051f325.jpg",
	},
	{
		id: 3,
		banner: "http://media.vietteltelecom.vn/upload/InternetInternetPackageBuyData/32/2d/d3/a362369aa612574c8d003b790831d4c98b08f509.jpg",
	},
	{
		id: 4,
		banner: "http://media.vietteltelecom.vn/upload/InternetInternetPackageBuyData/3c/fe/e3/75885c343c6584f9ca7760ee05721d7cc2ec6b72.jpg",
	},
	{
		id: 5,
		banner: "http://media.vietteltelecom.vn/upload/InternetInternetPackageBuyData/ec/fa/ae/32076843cced2accc8cffdfb53ea4251560a016e.jpg",
	},
];

const categories = [
	{
		id: 1,
		name: "Phân bón",
		slug: "phan-bon",
	},
	{
		id: 2,
		name: "Thuốc trừ sâu",
		slug: "thuoc-tru-sau",
	},
];

const products = [];

const topics = [
	{
		id: 1,
		name: "Tất cả",
		slug: "tat-ca",
	},
	{
		id: 2,
		name: "Tin dịch vụ",
		slug: "tin-dich-vu",
	},
	{
		id: 3,
		name: "Tin khuyến mãi",
		slug: "tin-khuyen-mai",
	},
];

const packages = [
	{
		id: 1,
		name: "Gói 1",
		slug: "goi-1",
		price: 10000,
		description:
			"R30: 20.000đ/30 ngày khi chuyển vùng quốc tế. Miễn phí nhận tin nhắn, các hướng dịch vụ khác (nghe/gọi/truy cập data...) được sử dụng và tính cước theo bảng giá cước thông thường.",
	},
	{
		id: 2,
		name: "Gói 2",
		slug: "goi-2",
		price: 20000,
		description:
			"R30: 20.000đ/30 ngày khi chuyển vùng quốc tế. Miễn phí nhận tin nhắn, các hướng dịch vụ khác (nghe/gọi/truy cập data...) được sử dụng và tính cước theo bảng giá cước thông thường.",
	},
	{
		id: 3,
		name: "Gói 3",
		slug: "goi-3",
		price: 30000,
		description:
			"R30: 20.000đ/30 ngày khi chuyển vùng quốc tế. Miễn phí nhận tin nhắn, các hướng dịch vụ khác (nghe/gọi/truy cập data...) được sử dụng và tính cước theo bảng giá cước thông thường.",
	},
	{
		id: 4,
		name: "Gói 4",
		slug: "goi-4",
		price: 40000,
		description:
			"R30: 20.000đ/30 ngày khi chuyển vùng quốc tế. Miễn phí nhận tin nhắn, các hướng dịch vụ khác (nghe/gọi/truy cập data...) được sử dụng và tính cước theo bảng giá cước thông thường.",
	},
	{
		id: 5,
		name: "Gói 5",
		slug: "goi-5",
		price: 50000,
		description:
			"R30: 20.000đ/30 ngày khi chuyển vùng quốc tế. Miễn phí nhận tin nhắn, các hướng dịch vụ khác (nghe/gọi/truy cập data...) được sử dụng và tính cước theo bảng giá cước thông thường.",
	},
];

const internetTVs = [
	{
		id: 1,
		name: "Gói 1",
		slug: "goi-1",
		price: 100000,
		speed: 100,
		apply: "Áp dụng 61 tỉnh thành",
		recommend:
			"Phù hợp với cá nhân, hộ gia đình có nhu cầu cao về băng thông cho mục đích học tập, giải trí thông thường.",
	},
	{
		id: 2,
		name: "Gói 2",
		slug: "goi-2",
		price: 200000,
		speed: 200,
		apply: "Áp dụng 61 tỉnh thành",
		recommend:
			"Phù hợp với cá nhân, hộ gia đình có nhu cầu cao về băng thông cho mục đích học tập, giải trí thông thường.",
	},
	{
		id: 3,
		name: "Gói 3",
		slug: "goi-3",
		price: 300000,
		speed: 300,
		apply: "Áp dụng 61 tỉnh thành",
		recommend:
			"Phù hợp với cá nhân, hộ gia đình có nhu cầu cao về băng thông cho mục đích học tập, giải trí thông thường.",
	},
	{
		id: 4,
		name: "Gói 4",
		slug: "goi-4",
		price: 400000,
		speed: 400,
		apply: "Áp dụng 61 tỉnh thành",
		recommend:
			"Phù hợp với cá nhân, hộ gia đình có nhu cầu cao về băng thông cho mục đích học tập, giải trí thông thường.",
	},
];

const shop = {
	name: "CÔNG TY TNHH HÓA NÔNG CUỘC SỐNG AN TOÀN",
	address:
		"Số 114 đường B3, KDC Hưng Phú 1, P. Hưng Phú, Q. Cái Răng, P Cần Thơ",
	phonenumber: "0932955922",
	email: "diemthuydh83@gmail.com",
};

export {
	shop,
	carouselItem,
	categories,
	products,
	topics,
	packages,
	internetTVs,
};
