import { Link } from "react-router-dom";
import Icon from "../Icon";
import MoneyFormat from "../MoneyFormat";
import { Card } from "react-bootstrap";
import Section from "../Section";
import Frame12 from "../../assets/banner/frame12.png";
import { INTERNET_TV } from "../../routes/routerUrl";
import Loading from "../Loading";
import NoData from "../NoData";

import Carousel from "react-multi-carousel";

import { responsive4Items } from "../../utils/carouselResponsiveItem";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";

const ListInternetTVHome = ({ title, allLink }) => {
	const [services, setServices] = useState([]);
	const [selectedInternetTV, setSelectedInternetTV] = useState(4);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getAllService(selectedInternetTV);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// API
	const getAllService = async (selectedInternetTV) => {
		setIsLoading(true);
		await axios
			.get(`/service/list`, {
				params: {
					serviceTypeId: selectedInternetTV,
					keyword: "",
					limit: 10,
				},
			})
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setServices(res.data.services);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleClickTab = (item) => {
		setSelectedInternetTV(item.id);
		getAllService(item.id);
	};

	return (
		<Section
			title={title}
			allLink={allLink}
			tabItems={[
				{
					id: 4,
					name: "Internet",
				},
				{
					id: 5,
					name: "Truyền hình",
				},
				{
					id: 6,
					name: "Combo Internet/Truyền hình",
				},
			]}
			tabIsActive={selectedInternetTV}
			tabOnClick={handleClickTab}
		>
			{isLoading ? (
				<Loading />
			) : services?.length > 0 ? (
				<Carousel
					responsive={responsive4Items}
					autoPlay
					infinite={true}
				>
					{services?.map((item) => {
						return (
							<Card
								key={item.id}
								style={{
									cursor: "pointer",
								}}
								className="radius-8 h-100 me-2"
							>
								<Link
									to={INTERNET_TV}
									style={{ textDecoration: "none" }}
								>
									<div
										style={{
											aspectRatio: "263/96",
											backgroundImage: `url(${Frame12})`,
											color: "var(--white)",
											fontSize: "2rem",
										}}
										className="image-default top-radius-8 text-uppercase fw-bold display-flex-center"
									>
										{item.name}
									</div>
								</Link>
								<div className="item-title text-center text-overflow-1-line">
									<MoneyFormat money={item.price} />/
									{item.period}
								</div>

								<div className="p-3">
									<div className="item-title text-center text-overflow-1-line">
										{item.speed}
									</div>

									<div className="my-3">
										<div className="text-overflow-2-line">
											<Icon
												icon="circle-check"
												style={{
													color: "var(--secondary-color)",
												}}
												className="me-2"
											/>
											{item.applyFor}
										</div>
										<div className="text-overflow-2-line">
											<Icon
												icon="circle-check"
												style={{
													color: "var(--secondary-color)",
												}}
												className="me-2"
											/>
											{item.suitable}
										</div>
									</div>

									<Link
										to={INTERNET_TV}
										className="underline-link text-center"
									>
										Xem chi tiết
									</Link>
								</div>
							</Card>
						);
					})}
				</Carousel>
			) : (
				<NoData />
			)}
		</Section>
	);
};

export default ListInternetTVHome;
