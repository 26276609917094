import { Button, Col, Container, Row } from "react-bootstrap";
import "../Style.css";

import BottomBG1 from "../../assets/banner/bottom-bg-1.png";
import BottomBG2Lg from "../../assets/banner/bottom-bg-2-lg.png";
import BottomBG2Sm from "../../assets/banner/bottom-bg-2-sm.png";

const BottomBannerInternetTV = () => {
	return (
		<>
			<Container className="container-wrapper mb-3">
				<Row className="justify-content-center">
					<Col md="4">
						<div
							style={{
								aspectRatio: "296/213",
								backgroundImage: `url(${BottomBG1})`,
							}}
							className="image-default"
						/>
					</Col>
					<Col xs="12" md="8">
						<div
							style={{ color: "white" }}
							className="item-title mb-3"
						>
							Điều gì khiến khách hàng luôn tin tưởng và sử dụng
							Internet Viettel?
						</div>
						<div>
							Tốc độ truy cập internet cao và ổn định. Sở hữu hơn
							365.000 km cáp quang và nhiều đường truyền cáp quang
							kết nối quốc tế trên biển và đất liền, đảm bảo tốt
							nhất dịch vụ cho Khách hàng. Áp dụng các công nghệ
							tiên tiến nhất nhằm mang lại trải nghiệm tối đa cho
							Khách hàng trong quá trình sử dụng dịch vụ (mesh
							wifi, xGSPON, Camera AI...)
						</div>
					</Col>
				</Row>
			</Container>

			<div
				style={{
					aspectRatio: "1441/300",
					backgroundImage: `url(${BottomBG2Lg})`,
				}}
				className="image-default d-none d-xl-block"
			>
				<Container className="container-wrapper d-flex align-items-center h-100">
					<div>
						<div
							style={{ fontSize: "2rem", color: "var(--white)" }}
							className="fw-bold"
						>
							HỖ TRỢ SAU BÁN ONLINE 24/7
						</div>

						<div
							style={{ fontSize: "2rem", color: "var(--white)" }}
							className="my-2"
						>
							INTERNET - TRUYỀN HÌNH
						</div>

						<Button className="online-sale-button rounded-pill me-3">
							ĐÓNG CƯỚC TRƯỚC
						</Button>

						<Button className="online-sale-button rounded-pill">
							HỔ TRỢ DỊCH VỤ
						</Button>

						<div>
							<Button className="online-sale-button rounded-pill mt-3">
								THANH TOÁN
							</Button>
						</div>
					</div>
				</Container>
			</div>

			<div
				style={{
					width: "100%",
					height: "12rem",
					backgroundImage: `url(${BottomBG2Sm})`,
					backgroundSize: "cover",
					overflow: "hidden",
				}}
				className="image-default d-block d-xl-none"
			>
				<Container className="container-wrapper d-flex flex-column justify-content-evenly h-100">
					<div>
						<div
							style={{ fontSize: "1rem", color: "var(--white)" }}
							className="fw-bold"
						>
							HỖ TRỢ SAU BÁN ONLINE 24/7
						</div>
						<div
							style={{ fontSize: "1rem", color: "var(--white)" }}
							className="mt-2"
						>
							INTERNET - TRUYỀN HÌNH
						</div>
					</div>

					<div>
						<div>
							<Button className="online-sale-button rounded-pill mt-2">
								ĐÓNG CƯỚC TRƯỚC
							</Button>
						</div>
						<div>
							<Button className="online-sale-button rounded-pill mt-2">
								HỔ TRỢ DỊCH VỤ
							</Button>
						</div>
						<div>
							<Button className="online-sale-button rounded-pill mt-2">
								THANH TOÁN
							</Button>
						</div>
					</div>
				</Container>
			</div>
		</>
	);
};

export default BottomBannerInternetTV;
