import { Col, Row } from "react-bootstrap";

const EnterpriseIntro2 = ({ title, description, className, style, image }) => {
	return (
		<div style={style} className={className}>
			<div className="item-title">{title}</div>
			<div className="mt-3 mt-md-4">{description}</div>

			<Row className="justify-content-center mt-5">
				<Col xs="12" md="4">
					<div
						style={{
							aspectRatio: "325/251",
							backgroundImage: `url(${image})`,
							backgroundSize: "cover",
						}}
						className="image-default"
					/>
				</Col>
			</Row>
		</div>
	);
};

export default EnterpriseIntro2;
