import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Components
import UserLayout from "../layouts/user/MasterLayout";
import Login from "../pages/user/auth/Login";

import Home from "../pages/user/Home";
import Introduction from "../pages/user/Introduction";
import NewEvent from "../pages/user/NewEvent/NewEvent";
import NewEventDetail from "../pages/user/NewEvent/NewEventDetail";

// Mobile Service
import Mobile from "../pages/user/Mobile/Mobile";
import DataPackage from "../pages/user/Mobile/DataPackage/DataPackage";
import DataPackageDetail from "../pages/user/Mobile/DataPackage/DataPackageDetail";

import MainPackage from "../pages/user/Mobile/MainPackage/MainPackage";
import MainPackageDetail from "../pages/user/Mobile/MainPackage/MainPackageDetail";

// Internet TV
import InternetTV from "../pages/user/InternetTV/InternetTV";
import Internet from "../pages/user/InternetTV/Internet";
import TV from "../pages/user/InternetTV/TV";
import Combo from "../pages/user/InternetTV/Combo";
import InternetTVDetail from "../pages/user/InternetTV/InternetTVDetail";

// Enterprise
import Enterprise from "../pages/user/Enterprise/Enterprise";
import ViettelCA from "../pages/user/Enterprise/ViettelCA";
import SInvoice from "../pages/user/Enterprise/SInvoice";
import VContract from "../pages/user/Enterprise/VContract";
import VMark from "../pages/user/Enterprise/VMark";
import MySign from "../pages/user/Enterprise/MySign";
import EnterpriseOrder from "../pages/user/Enterprise/EnterpriseOrder";

import Customer from "../pages/user/Customer/Customer";

import AdminLayout from "../layouts/admin/MasterLayout";
import Dashboard from "../pages/admin/Dashboard";

// New Event
import TopicAdmin from "../pages/admin/NewEvent/Topic/Topic";
import NewEventAdmin from "../pages/admin/NewEvent/NewEvent/NewEvent";

// Mobile Service
import DataPackageAdmin from "../pages/admin/Mobile/DataPackage/DataPackage";
import MainPackageAdmin from "../pages/admin/Mobile/MainPackage/MainPackage";

// Internet TV
import InternetAdmin from "../pages/admin/InternetTV/Internet/Internet";
import TVAdmin from "../pages/admin/InternetTV/TV/TV";
import ComboInternetTVAdmin from "../pages/admin/InternetTV/Combo/ComboInternetTV";

import EnterpriseAdmin from "../pages/admin/Enterprise/Enterprise";
import Setting from "../pages/admin/Setting";

// URLs
import {
	INTRODUCTION,
	NEW_EVENT,
	MOBILE,
	INTERNET_TV,
	ENTERPRISE_SERVICE,
	VIETTEL_CA,
	S_INVOICE,
	V_CONTRACT,
	V_MARK,
	MY_SIGN,
	CUSTOMER_HELP,
	ENTERPRISE_ORDER,
	LOGIN,
} from "./routerUrl";

export default function router() {
	return (
		<>
			<BrowserRouter>
				<ToastContainer position="top-right" />

				<ScrollToTop />
				<Routes>
					<Route path="/" element={<UserLayout />}>
						<Route index element={<Home />} />

						<Route path={LOGIN} element={<Login />} />
						{/* <Route path={REGISTER} element={<Register />} /> */}

						<Route path={INTRODUCTION} element={<Introduction />} />
						<Route path={NEW_EVENT} element={<NewEvent />} />
						<Route
							path={`${NEW_EVENT}/:slug`}
							element={<NewEventDetail />}
						/>
						{/* Mobile Service */}
						<Route path={MOBILE} element={<Mobile />} />
						<Route
							path={`${MOBILE}/goi-data`}
							element={<DataPackage />}
						/>
						<Route
							path={`${MOBILE}/goi-cuoc`}
							element={<MainPackage />}
						/>
						<Route
							path={`${MOBILE}/goi-data/:slug`}
							element={<DataPackageDetail />}
						/>
						<Route
							path={`${MOBILE}/goi-cuoc/:slug`}
							element={<MainPackageDetail />}
						/>

						{/* Internet TV */}
						<Route path={INTERNET_TV} element={<InternetTV />} />
						<Route
							path={`${INTERNET_TV}/internet`}
							element={<Internet />}
						/>
						<Route
							path={`${INTERNET_TV}/truyen-hinh`}
							element={<TV />}
						/>
						<Route
							path={`${INTERNET_TV}/combo`}
							element={<Combo />}
						/>
						<Route
							path={`${INTERNET_TV}/goi-cuoc/:slug`}
							element={<InternetTVDetail />}
						/>

						{/* Enterprise Service */}
						<Route
							path={ENTERPRISE_SERVICE}
							element={<Enterprise />}
						/>
						<Route path={VIETTEL_CA} element={<ViettelCA />} />
						<Route path={S_INVOICE} element={<SInvoice />} />
						<Route path={V_CONTRACT} element={<VContract />} />
						<Route path={V_MARK} element={<VMark />} />
						<Route path={MY_SIGN} element={<MySign />} />
						<Route
							path={`${ENTERPRISE_ORDER}/:slug`}
							element={<EnterpriseOrder />}
						/>

						<Route path={CUSTOMER_HELP} element={<Customer />} />
					</Route>

					<Route path="/admin" element={<AdminLayout />}>
						<Route index element={<Dashboard />} />

						{/* New Event */}
						<Route path="topic" element={<TopicAdmin />} />
						<Route path="new" element={<NewEventAdmin />} />

						{/* Mobile */}
						<Route
							path="dataPackage"
							element={<DataPackageAdmin />}
						/>
						<Route
							path="mainPackage"
							element={<MainPackageAdmin />}
						/>

						{/* InternetTV */}
						<Route path="internet" element={<InternetAdmin />} />
						<Route path="tv" element={<TVAdmin />} />
						<Route
							path="comboInternetTV"
							element={<ComboInternetTVAdmin />}
						/>

						<Route
							path="enterprise"
							element={<EnterpriseAdmin />}
						/>
						<Route path="setting" element={<Setting />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</>
	);
}
