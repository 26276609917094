import { useEffect } from "react";

import { Button, Col, Container, Row } from "react-bootstrap";
import MyBreadcrumb from "../../../components/MyBreadcrumb";
import ListInternetTVDetail from "../../../components/InternetTV/ListInternetTVDetail";

import IconHome from "../../../assets/icon/icon-home.svg";
import IconDola from "../../../assets/icon/icon-dola.svg";
import IconIt from "../../../assets/icon/icon-it.svg";

import ComboBG2 from "../../../assets/banner/Combo-bg-2.jpg";
import Banner from "../../../assets/banner/bg-internet-pack.png";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { useParams } from "react-router-dom";
import ClientLoading from "../../../components/ClientLoading";
import MoneyFormat from "../../../components/MoneyFormat";

const titleItems = [
	{
		id: 1,
		label: "Tên gói",
		icon: IconHome,
	},
	{
		id: 2,
		label: "Giá gói",
		icon: IconDola,
	},
	{
		id: 3,
		label: "Tốc độ",
		icon: IconIt,
	},
];

const InternetTVDetail = () => {
	useEffect(() => {
		document.title = "Chi tiết gói";
	}, []);

	let { slug } = useParams();

	const [intenetTV, setInternetTV] = useState({});
	const [relatedIntenetTV, setRelatedIntenetTV] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		document.title = "Chi tiết gói";

		getDetailInternetTV(slug);
	}, [slug]);

	const getDetailInternetTV = (slug) => {
		setIsLoading(true);
		axios
			.get(`/client/service/${slug}`)
			.then((res) => {
				setIsLoading(false);

				if (res.data.errCode === 0) {
					setInternetTV(res.data.service);
					setRelatedIntenetTV(res.data.relatedServices);
				} else {
					toast(res.data.errMessage, {
						type: "error",
						autoClose: 1000,
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<>
			<div
				style={{
					aspectRatio: "1439/238",
					backgroundImage: `url(${Banner})`,
					backgroundSize: "cover",
				}}
				className="image-default"
			>
				<Container className="container-wrapper d-flex flex-column justify-content-between h-100">
					<MyBreadcrumb
						list={["Internet/Truyền hình", "Chi tiết gói"]}
					/>

					<div
						style={{ color: "var(--white)" }}
						className="inter-title fw-bold text-center pb-3"
					>
						Chi tiết gói {intenetTV.name}
					</div>
				</Container>
			</div>

			<Container className="container-wrapper my-3">
				{isLoading ? <ClientLoading /> : ""}

				<Row className="justify-content-center">
					<Col xs="12" md="10">
						<Row className="radius-8 border px-3 py-5 my-3">
							{titleItems.map((item) => {
								let value = "";

								if (item.id === 1) {
									value = intenetTV.name;
								}

								if (item.id === 2) {
									value = intenetTV.price;
								}

								if (item.id === 3) {
									value = intenetTV.speed;
								}

								return (
									<Col key={item.id} className="h-100">
										<Row className="justify-content-center align-items-center">
											<Col
												xs="12"
												md="2"
												className="px-0"
											>
												<div
													style={{
														backgroundImage: `url(${item.icon})`,
													}}
													className="image-default inter-detail-icon"
												/>
											</Col>
											<Col
												xs="12"
												md="10"
												className="px-0"
											>
												<div className="inter-detail-sub">
													{item.label}
												</div>
												<div className="inter-detail-title fw-bold text-overflow-1-line">
													{item.id === 2 ? (
														<MoneyFormat
															money={value}
														/>
													) : (
														value
													)}
												</div>
											</Col>
										</Row>
									</Col>
								);
							})}
						</Row>

						<Row>
							<Col xs="12" md="4">
								<div
									style={{
										aspectRatio: "361/428",
										backgroundImage: `url(${ComboBG2})`,
									}}
									className="image-default d-none d-md-block"
								/>

								<Button className="primary-button w-100 my-3">
									Đăng ký
								</Button>
							</Col>
							<Col xs="12" md="8">
								<div
									dangerouslySetInnerHTML={{
										__html: intenetTV.content,
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

				<ListInternetTVDetail
					listItems={relatedIntenetTV}
					title={`CÁC GÓI TƯƠNG TỰ`}
				/>
			</Container>
		</>
	);
};

export default InternetTVDetail;
