import { Container } from "react-bootstrap";
import EnterpriseIntro2 from "../../../components/Enterprise/EnterpriseIntro2";
import ListAdvantages2 from "../../../components/Enterprise/ListAdvantages2";
import ListWhys2 from "../../../components/Enterprise/ListWhys2";
import ListSell2 from "../../../components/Enterprise/ListSell2";
import { packages } from "../../../InitialData";
import BottomBannerEnterprise from "../../../components/Enterprise/BottomBannerEnterprise";
import ListFeatures2 from "../../../components/Enterprise/ListFeatures2";

const advantageItems = [
	{
		id: 1,
		icon: "https://vietteltelecom.vn/images/sme/smev3/telecomunication.png",
		content: "Miễn phí data khi truy cập ứng dụng vContract trên di động",
	},
	{
		id: 2,
		icon: "https://vietteltelecom.vn/images/sme/smev3/sms.png",
		content: "Ưu đãi SMS Brandname và eKYC giá tốt nhất thị trường",
	},
	{
		id: 3,
		icon: "https://vietteltelecom.vn/images/sme/smev3/gift.png",
		content:
			"Ưu đãi lên đến 15% cho gói combo vContract + Viettel-CA + Hóa đơn điện tử Viettel.",
	},
	{
		id: 4,
		icon: "https://vietteltelecom.vn/images/sme/smev3/clock.png",
		content:
			"Kết nối đến cơ sở dữ liệu Viễn thông lớn nhất Việt Nam để định danh người dùng ký eKYC",
	},
	{
		id: 5,
		icon: "https://vietteltelecom.vn/images/sme/smev3/care.png",
		content:
			"Kênh bán và bộ máy hỗ trợ, chăm sóc khách hàng của Viettel rộng khắp 63 Tỉnh/thành phố",
	},
	{
		id: 6,
		icon: "https://vietteltelecom.vn/images/sme/smev3/cup.png",
		content: "Là giải pháp công nghệ thông tin đạt giải Sao Khuê 2021",
	},
];

const featureItems = [
	{
		id: 1,
		content:
			"Khởi tạo, ký và tra cứu hợp đồng/tài liệu, kí hợp đồng, gửi nhận thông tin hợp đồng/tài liệu qua email/SMS...",
	},
	{
		id: 2,
		content: "Quản lý và lưu trữ hợp đồng /tài liệu dễ dàng",
	},
	{
		id: 3,
		content:
			"Đa dạng phương thức ký: Ký số (USB Token, Cloud CA, Mobile CA) /OTP/eKYC/ ký ảnh",
	},
	{
		id: 4,
		content: "Áp dụng cho nhiều loại thỏa thuận/chứng từ/hợp đồng",
	},
];

const whyItems = [
	{
		id: 1,
		content:
			"Tiết kiệm lên đến 90% chi phí in ấn, giao vận, lưu trữ hợp đồng",
	},
	{
		id: 2,
		content: "Tăng sự hài lòng của khách hàng khi giao dịch với DN",
	},
	{
		id: 3,
		content: "Cắt giảm 90% thời gian ký kết hợp đồng",
	},
	{
		id: 4,
		content: "Phá bỏ giới hạn địa lý trong việc ký kết hợp đồng",
	},
	{
		id: 5,
		content: "Thuận tiện cho việc quản lý hợp đồng",
	},
	{
		id: 6,
		content: "Đa dạng phương thức ký: Ký số/OTP/eKYC/ ký ảnh",
	},
];

const VContract = () => {
	return (
		<>
			<Container className="container-wrapper">
				<EnterpriseIntro2
					title={`SẢN PHẨM VCONTRACT LÀ GÌ?`}
					description={`Hợp đồng điện tử Viettel (vContract) cho phép doanh nghiệp quản lý và ký số các loại hợp đồng, tài liệu với khách hàng, đối tác qua internet. Hợp đồng điện tử Viettel đáp ứng các quy định của pháp luật và là giải pháp thay thế hợp đồng giấy hiệu quả, có giá trị pháp lý như việc ký kết hợp đồng giấy theo phương thức truyền thống.`}
					image={`https://viettel.vn/images/sme/smev3/service-vcontract.png`}
					className={`text-center`}
				/>
			</Container>

			{/* Advantage */}
			<ListAdvantages2
				title={`ƯU ĐIỂM VƯỢT TRỘI`}
				items={advantageItems}
				col={3}
			/>

			<Container className="container-wrapper">
				{/* Feature */}
				<ListFeatures2
					title={`TÍNH NĂNG SẢN PHẨM`}
					items={featureItems}
				/>
			</Container>

			{/* Why */}
			<ListWhys2 title={`LÝ DO LỰA CHỌN VCONTRACT`} items={whyItems} />

			<Container className="container-wrapper">
				{/* Sell */}
				<ListSell2
					title={`THÔNG TIN CÁC GÓI`}
					items={packages}
					type={1}
				/>

				<BottomBannerEnterprise
					title={`HỢP ĐỒNG ĐIỆN TỬ VIETTEL (VCONTRACT)`}
					content={`Tiết kiệm lên đến 90% chi phí và thời gian ký kết hợp đồng`}
				/>
			</Container>
		</>
	);
};

export default VContract;
