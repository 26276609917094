import React, { useState } from "react";
import { Link } from "react-router-dom";
import { INTRODUCTION, NEW_EVENT } from "../../routes/routerUrl";
import Icon from "../../components/Icon";
import navbarItems from "../../utils/navbarItems";

const Sidebar = ({ setShowSideBar, showSideBar }) => {
	const [showSubSidebar, setShowSubSidebar] = useState(0);

	return (
		<nav
			className={`${
				showSideBar ? "sidebar-menu active" : "sidebar-menu"
			} p-4`}
		>
			{showSubSidebar !== 0 ? (
				<div>
					<div
						className="sidebar-text py-3 mb-3 border-bottom"
						onClick={() => setShowSubSidebar(0)}
					>
						<Icon icon="chevron-left" /> Quay lại
					</div>
					<div className="sidebar-text py-3">
						{
							navbarItems.find(
								(item) => item.id === showSubSidebar,
							).title
						}
					</div>
					<div className="px-3 pb-3 mb-3 border-bottom">
						{navbarItems
							.find((item) => item.id === showSubSidebar)
							.subTitle.map((item) => {
								return (
									<div
										key={item.id}
										className="d-flex justify-content-between align-items-center"
									>
										<Link
											to={item.link}
											className="sidebar-sub-text"
											onClick={() =>
												setShowSideBar(false)
											}
										>
											<div className="py-2 ">
												{item.title}
											</div>
										</Link>
									</div>
								);
							})}
					</div>
				</div>
			) : (
				<div>
					<div className="pb-3 mb-3 border-bottom">
						{navbarItems.map((item) => {
							return (
								<div
									key={item.id}
									className="d-flex justify-content-between align-items-center"
								>
									<Link
										to={item.link}
										className="sidebar-text"
										onClick={() => setShowSideBar(false)}
									>
										<div className="py-2 ">
											{item.title}
										</div>
									</Link>

									<div
										style={{
											cursor: "pointer",
										}}
										onClick={() =>
											setShowSubSidebar(item.id)
										}
									>
										<Icon icon="chevron-right" />
									</div>
								</div>
							);
						})}
					</div>

					<div className="pb-3 mb-3 border-bottom">
						<Link
							to={INTRODUCTION}
							className="sidebar-text"
							onClick={() => setShowSideBar(false)}
						>
							<div className="py-2 ">Giới thiệu viettel</div>
						</Link>
						<Link
							to={`${NEW_EVENT}`}
							className="sidebar-text"
							onClick={() => setShowSideBar(false)}
						>
							<div className="py-2 ">Tin tức sự kiện</div>
						</Link>
					</div>
				</div>
			)}
		</nav>
	);
};

export default Sidebar;
