import Carousel from "react-multi-carousel";
import { carouselItem } from "../InitialData";

const responsiveBanner = {
	desktop: {
		breakpoint: { max: 3000, min: 0 },
		items: 1,
		slidesToSlide: 1,
	},
};

const CarouselBanner = () => {
	return (
		<Carousel responsive={responsiveBanner} autoPlay infinite={true}>
			{carouselItem.map((item) => {
				return (
					<div
						key={item.id}
						style={{
							aspectRatio: "16/5",
							backgroundImage: `url(${item.banner})`,
						}}
						className="image-default"
					/>
				);
			})}
		</Carousel>
	);
};

export default CarouselBanner;
