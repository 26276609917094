export const LOGIN = "/dang-nhap";

export const INTRODUCTION = "/gioi-thieu-viettel";

export const NEW_EVENT = "/tin-tuc";

export const MOBILE = "/di-dong";

export const INTERNET_TV = "/internet-truyenhinh";

export const ENTERPRISE_SERVICE = "/sme";

export const CUSTOMER_HELP = "/ho-tro-khach-hang";

export const VIETTEL_CA = "/viettel-ca";

export const S_INVOICE = "/s-invoice";

export const V_CONTRACT = "/v-contract";

export const V_MARK = "/v-mark";

export const MY_SIGN = "/mysign";

export const ENTERPRISE_ORDER = "/sme-order";
