import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

const MyBreadcrumb = ({ isWhiteTheme, list }) => {
	return (
		<Breadcrumb className="py-4">
			<Breadcrumb.Item
				linkAs={Link}
				linkProps={{ to: "/" }}
				className={isWhiteTheme ? "my-breadcrumb1" : "my-breadcrumb"}
			>
				Trang chủ
			</Breadcrumb.Item>
			{list?.map((item, index) => {
				return (
					<Breadcrumb.Item
						key={index}
						className={
							index === list.length - 1
								? "my-breadcrumb-active"
								: isWhiteTheme
								? "my-breadcrumb1"
								: "my-breadcrumb"
						}
					>
						{item}
					</Breadcrumb.Item>
				);
			})}
		</Breadcrumb>
	);
};

export default MyBreadcrumb;
