import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { INTERNET_TV } from "../../../routes/routerUrl";

import ComboBg from "../../../assets/banner/combo-bg.jpg";

const ComboItem = ({ item, className }) => {
	return (
		<div
			style={{ backgroundColor: "white" }}
			className={`h-100 ${className}`}
		>
			<Link to={`${INTERNET_TV}/goi-cuoc/${item.slug}`}>
				<div
					style={{
						height: "11rem",
						backgroundImage: `url(${ComboBg})`,
						backgroundSize: "cover",
					}}
					className="image-default"
				/>
			</Link>

			<div className="p-3">
				<Link
					to={`${INTERNET_TV}/goi-cuoc/${item.slug}`}
					className="item-title-hover text-overflow-1-line my-1"
				>
					{item.name}
				</Link>
				<div className="item-text text-overflow-2-line">
					{item.description}
				</div>

				<Link to={`${INTERNET_TV}/goi-cuoc/${item.slug}`}>
					<Button className="primary-button px-5 mt-3">
						Đăng ký
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default ComboItem;
