const Loading = () => {
	return (
		<div className="display-flex-center my-5">
			<div
				className="spinner-border"
				style={{
					width: "3rem",
					height: "3rem",
					color: "var(--primary-color)",
				}}
				role="status"
			>
				<span className="sr-only">Đang tải</span>
			</div>
		</div>
	);
};

export default Loading;
