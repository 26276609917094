import { Col, Row } from "react-bootstrap";
import EnterpriseSection from "./EnterpriseSection";

const ListFeatures = ({ title, items, backgroundImage }) => {
	return (
		<EnterpriseSection title={title}>
			<div
				style={{
					backgroundImage: `url(${backgroundImage})`,
				}}
				className="enterprise-feature-img-bg image-default"
			>
				<Row className="h-100">
					<Col xs="12" md="10" xl="6"></Col>
					<Col
						xs="12"
						md="10"
						xl="6"
						className="d-flex align-items-center h-100"
					>
						<div className="mx-3 mx-xl-0">
							{items.map((item) => {
								return (
									<div
										key={item.id}
										className="d-flex align-items-center mb-3"
									>
										<div
											style={{
												color: "var(--white)",
												backgroundColor: "white",
												borderRadius: 16,
											}}
											className="me-3 p-3"
										>
											<div
												style={{
													color: "var(--white)",
													backgroundImage: `url(${item.icon})`,
												}}
												className="image-default  enterprise-feature-icon"
											/>
										</div>
										<div
											style={{
												color: "white",
												fontSize: "1.5rem",
												width: "60%",
											}}
											className="text-overflow-2-line"
										>
											{item.content}
										</div>
									</div>
								);
							})}
						</div>
					</Col>
				</Row>
			</div>
		</EnterpriseSection>
	);
};

export default ListFeatures;
