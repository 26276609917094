import ReactSelect from "react-select";

const Select = ({
	options,
	placeholder,
	optionLabel,
	optionValue,
	onChange,
	className,
}) => {
	return (
		<ReactSelect
			options={options}
			placeholder={placeholder || "Chọn..."}
			noOptionsMessage={() => "Không có lựa chọn"}
			getOptionLabel={(option) => option[optionLabel]}
			getOptionValue={(option) => option[optionValue]}
			onChange={(choice) => onChange(choice)}
			className={`my-input ${className}`}
			components={{
				IndicatorSeparator: () => null,
			}}
			styles={{
				dropdownIndicator: (base) => ({
					...base,
					color: "var(--primary-color)",
					"&:hover": {
						color: "var(--primary-color)",
					},
				}),
				control: (styles) => ({
					...styles,
					width: "100%",
					border: 0,
					boxShadow: "none",
					"&:hover": {
						border: 0,
					},
				}),
			}}
		/>
	);
};

export default Select;
