import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Icon from "../../components/Icon";
import LogoFooter from "../../assets/image/Logo-Footer.png";
import { Link } from "react-router-dom";

const footerItem = [
	{
		id: 1,
		title: "Sản phẩm dịch vụ",
		subTitle: [
			{
				id: 1,
				title: "Dịch vụ di động",
			},
			{
				id: 2,
				title: "Internet / Truyền Hình",
			},
			{
				id: 3,
				title: "Dịch vụ doanh nghiệp",
			},
			{
				id: 4,
				title: "Hỗ trợ khách hàng",
			},
		],
	},
	{
		id: 2,
		title: "Sản phẩm dịch vụ",
		subTitle: [
			{
				id: 1,
				title: "Dịch vụ di động",
			},
			{
				id: 2,
				title: "Internet / Truyền Hình",
			},
			{
				id: 3,
				title: "Dịch vụ doanh nghiệp",
			},
			{
				id: 4,
				title: "Hỗ trợ khách hàng",
			},
		],
	},
	{
		id: 3,
		title: "Sản phẩm dịch vụ",
		subTitle: [
			{
				id: 1,
				title: "Dịch vụ di động",
			},
			{
				id: 2,
				title: "Internet / Truyền Hình",
			},
			{
				id: 3,
				title: "Dịch vụ doanh nghiệp",
			},
			{
				id: 4,
				title: "Hỗ trợ khách hàng",
			},
		],
	},
	{
		id: 4,
		title: "Sản phẩm dịch vụ",
		subTitle: [
			{
				id: 1,
				title: "Dịch vụ di động",
			},
			{
				id: 2,
				title: "Internet / Truyền Hình",
			},
			{
				id: 3,
				title: "Dịch vụ doanh nghiệp",
			},
			{
				id: 4,
				title: "Hỗ trợ khách hàng",
			},
		],
	},
];

const Footer = () => {
	return (
		<footer>
			<section className="footer-main py-4">
				<Container>
					<Row>
						<Col xs="12" md="3">
							<img src={LogoFooter} alt="Viettel" />

							<div className="footer-text text-uppercase my-4">
								WEBSITE MUA SẮM ONLINE CHÍNH THỨC CỦA VIETTEL
								TELECOM.
							</div>

							<div className="footer-text">
								Cơ quan chủ quản: Tổng Công ty Viễn thông
								Viettel (Viettel Telecom) - Chi nhánh Tập đoàn
								Công nghiệp - Viễn thông Quân đội. Mã số doanh
								nghiệp: 0100109106-011 do Sở Kế hoạch và Đầu tư
								Thành phố Hà Nội cấp lần đầu ngày 18/07/2005,
								sửa đổi lần thứ 15 ngày 18/12/2018. Chịu trách
								nhiệm nội dung: Ông Cao Anh Sơn
							</div>
						</Col>

						<Col xs="0" md="9" className="d-none d-md-block">
							<Row>
								{footerItem.map((footItem) => {
									return (
										<Col
											xs="0"
											md={12 / footerItem.length}
											key={footItem.id}
											className="d-flex justify-content-center"
										>
											<div>
												<div className="footer-title">
													{footItem.title}
												</div>
												{footItem.subTitle.map(
													(subItem) => {
														return (
															<div
																key={subItem.id}
															>
																<Link
																	to="/"
																	className="footer-link"
																>
																	{
																		subItem.title
																	}
																</Link>
															</div>
														);
													},
												)}
											</div>
										</Col>
									);
								})}
							</Row>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="footer-extra text-center py-5">
				<Icon icon="location-dot" /> Trụ sở: Số 1 Giang Văn Minh, P Kim
				Mã, Q Ba Đình, TP Hà Nội. &nbsp; | &nbsp; <Icon icon="phone" />{" "}
				Chăm sóc khách hàng: 1800 8098/198 (miễn phí)
			</section>

			<section className="footer-main py-3">
				<h6 className="footer-title text-center">Developed by Katec</h6>
			</section>
		</footer>
	);
};

export default Footer;
