import { Container } from "react-bootstrap";
import EnterpriseIntro from "../../../components/Enterprise/EnterpriseIntro";
import ListFeatures from "../../../components/Enterprise/ListFeatures";
import { packages } from "../../../InitialData";
import ListWhys from "../../../components/Enterprise/ListWhys";
import ListAdvantages from "../../../components/Enterprise/ListAdvantage";
import ListSell from "../../../components/Enterprise/ListSell";
// import GuideVideo from "../../../components/Enterprise/GuideVideo";

const whyItems = [
	{
		id: 1,
		icon: "https://viettel.vn/images/sme/home/vt-ca-1.jpg",
		title: "Cơ hội sử dụng đồng bộ nhiều dịch vụ",
		content:
			"Viettel là nhà cung cấp đa dịch vụ, doanh nghiệp có cơ hội dùng nhiều dịch vụ đồng bộ trên hạ tầng mạng lớn của Viettel, với giá ưu đãi (dịch vụ tin nhắn thương hiệu, email server, lưu trữ dữ liệu, kết nối, các dịch vụ hướng đối tượng: trường học, y tế, giao thông...).",
	},
	{
		id: 2,
		icon: "https://viettel.vn/images/sme/home/vt-ca-2.jpg",
		title: "Hạ tầng mạng lớn, bảo mật",
		content:
			"Khả năng phát hành hàng triệu hóa đơn/ngày, hệ thống sử dụng công nghệ bảo mật nhiều lớp, an toàn tuyệt đối trong việc quản lý, lưu trữ hóa đơn.",
	},
	{
		id: 3,
		icon: "https://viettel.vn/images/sme/home/vt-ca-3.jpg",
		title: "Triển khai nhanh, chất lượng",
		content:
			"Khởi tạo tài khoản cho Doanh nghiệp ngay sau khi ký hợp đồng. Viettel có các Trung tâm CNTT trên khắp 63 tỉnh/Tp, sẵn sàng triển khai, tích hợp khi có yêu cầu, hỗ trợ 24/7.",
	},
	{
		id: 4,
		icon: "https://viettel.vn/images/sme/home/vt-ca-4.jpg",
		title: "Hệ thống Hóa đơn điện tử SInvoice thông minh, linh hoạt, hiệu quả",
		content:
			"Khả năng mở rộng phù hợp với nhiều loại hóa đơn đặc thù; đáp ứng da dạng các loại hình doanh nghiệp từ nhỏ đến lớn. Khả năng tích hợp với đa dạng các phần mềm kế toán, bán hàng, quản lý doanh nghiệp.",
	},
];

const featureItems = [
	{
		id: 1,
		icon: "https://vietteltelecom.vn/images/sme/Sinvoice/s-invoice-1.png",
		content: "Đầy đủ tính năng, linh hoạt quản lý.",
	},
	{
		id: 2,
		icon: "https://vietteltelecom.vn/images/sme/Sinvoice/s-invoice-2.png",
		content:
			"Thư viện mẫu hóa đơn đa dạng. Tạo mới, khai báo cho mẫu hóa đơn mới nhanh.",
	},
	{
		id: 3,
		icon: "https://vietteltelecom.vn/images/sme/Sinvoice/s-invoice-3.png",
		content: "Lập hóa đơn nhanh chóng, đơn giản.",
	},
	{
		id: 4,
		icon: "https://vietteltelecom.vn/images/sme/Sinvoice/s-invoice-4.png",
		content: "Hỗ trợ ký bằng thiết bị ký số.",
	},
	{
		id: 5,
		icon: "https://vietteltelecom.vn/images/sme/Sinvoice/s-invoice-5.png",
		content:
			"Có module kết nối tới Tổng Cục Thuế theo mẫu của Tổng Cục Thuế.",
	},
	{
		id: 6,
		icon: "https://vietteltelecom.vn/images/sme/Sinvoice/s-invoice-6.png",
		content: "Có sẵn Portal cho khách hàng tra cứu hóa đơn.",
	},
	{
		id: 7,
		icon: "https://vietteltelecom.vn/images/sme/Sinvoice/s-invoice-7.png",
		content: "Thông tin chi tiết tính năng",
	},
];

const advantageItems = [
	{
		id: 1,
		icon: "https://viettel.vn/images/sme/Sinvoice/icon/save.png",
		content:
			"Tiết kiệm chi phí in ấn, giấy, mực cho hóa đơn; gửi nhận hóa đơn cho khách hàng; kho bãi lưu trữ, bảo quản.",
	},
	{
		id: 2,
		icon: "https://viettel.vn/images/sme/Sinvoice/icon/clock.png",
		content: "Giảm thiểu các thủ tục hành chính, lưu trữ.",
	},
	{
		id: 3,
		icon: "https://viettel.vn/images/sme/Sinvoice/icon/timer.png",
		content:
			"Dịch vụ hiện đại, nhanh chóng nhận hóa đơn, thuận tiện tra cứu.",
	},
	{
		id: 4,
		icon: "https://viettel.vn/images/sme/Sinvoice/icon/noTack.png",
		content: "Không thể làm giả hóa đơn.",
	},
	{
		id: 5,
		icon: "https://viettel.vn/images/sme/Sinvoice/icon/seach.png",
		content: "Thuận tiện tra cứu online MỌI LÚC-MỌI NƠI.",
	},
];

const SInvoice = () => {
	return (
		<>
			<div
				style={{
					aspectRatio: "1715/668",
					backgroundImage: `url(https://media.vietteltelecom.vn/upload/c2/87/7c/4ecdcc7fbac834e39b7218458e4b105f3208fc7a.jpg)`,
				}}
				className="image-default mb-5"
			/>

			<Container className="container-wrapper">
				<EnterpriseIntro
					title="HÓA ĐƠN ĐIỆN TỬ SINVOICE LÀ GÌ?"
					description={
						"Sinvoice giúp doanh nghiệp khởi tạo, lập, gửi, nhận, quản lý hóa đơn bằng phương tiện điện tử, ký bằng chữ ký số và có giá trị pháp lý như hóa đơn giấy. SInvoice phục vụ mọi khách hàng có nhu cầu, đủ điều kiện theo quy định Pháp luật. Dịch vụ cung cấp từ 2016, liên tục cải tiến đáp ứng yêu cầu sử dụng của khách hàng cũng như nghiệp vụ quản lý từ cơ quan Nhà nước."
					}
					className={`text-center`}
					buttonClass={`justify-content-center`}
				/>

				{/* Why */}
				<ListWhys
					title={`TẠI SAO PHẢI LỰA CHỌN SINVOICE?`}
					items={whyItems}
				/>
			</Container>

			{/* Feature */}
			<ListFeatures
				title="TÍNH NĂNG SẢN PHẨM"
				items={featureItems}
				backgroundImage={`https://vietteltelecom.vn/images/sme/Sinvoice/Artboard.png`}
			/>

			<Container className="container-wrapper">
				{/* Advantage */}
				<ListAdvantages
					title="ƯU ĐIỂM VƯỢT TRỘI"
					items={advantageItems}
					col={5}
				/>

				{/* Sell */}
				<ListSell title="BẢNG GIÁ" items={packages} />

				{/* Video */}
				{/* <GuideVideo
					title={`VIDEO HƯỚNG DẪN SỬ DỤNG DỊCH VỤ`}
					videoLink={`https://www.youtube.com/watch?v=nPPTRU1A6ZM`}
				/> */}
			</Container>
		</>
	);
};

export default SInvoice;
